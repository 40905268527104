<template>
  <div class="bgbody">
    <div class="mybg"> </div>

    <div class="wrapperat">
      <div class="image-holder">
        <div class="logo">
          <img src="img/logo.png" class="logoimg" alt>
        </div>

      </div>



      <form action>
        <div id="wizard">
          
          <!-- Login Section -->
          <div v-show="login">
            <div class="titleh" v-if="!iscreateaccount">Login</div>
            <p v-if="!iscreateaccount">Log in to your account to fill out the application form. </p>
            <div class="titleh" v-if="iscreateaccount">Signup</div>
            <p v-if="iscreateaccount">Signup to fill the application form</p>
            <section>
                     

<div class="form-row">
           
 

            <div class="form-group col-md-12">
              <label for="inputEmail4">Email</label>
              <input type="text" :keyPressed="loginvalidate()" v-model="Useremail" class="form-control2" name="email" id="email" placeholder="Enter Your Email Id"> 
            </div>
           
             
            

            <div class="form-group col-md-12" v-if="iscreateaccount">
              <label for="inputEmail4">Password</label>
              <input type="password" :keyPressed="loginvalidate()" v-model="UserPassword" class="form-control2" name="UserPassword" id="UserPassword" placeholder="Enter Password"> 
            </div>

            <div class="form-group col-md-12" v-if="iscreateaccount">
              <label for="inputEmail4">Confirm Password</label>
              <input type="password" :keyPressed="loginvalidate()" v-model="UserConfirmPassword" class="form-control2" name="UserConfirmPassword" id="UserConfirmPassword" placeholder="Enter Confirm Password"> 
            </div>
         

            
          </div>
          </section>

            <button type="button" v-if="!SkipOTP" class="btn btn-primary" @click="logincheck"> Send OTP</button>
            <button type="button" v-if="SkipOTP" class="btn btn-primary" @click="logincheck"> Create</button>  
           <span v-if="!iscreateaccount"> Don't have an account? <span style="color: blue;cursor: pointer;" @click="iscreateaccount=true" >Create an account.</span></span>
          </div>
          <!-- OTP Section -->
          <div v-show="OTPpage">
            <div class="titleh">Login</div>
            <p>information regarding registration page </p>
            <section>
                     

            <div class="form-row">
            
              <label for="inputEmail4">OTP</label>
              <input type="text" maxlength="4"  class="form-control2" name="otp" id="otp" v-model="otp" placeholder="Enter Your OTP"> 
           
         
          </div>

          </section>

            <button type="button" class="btn btn-primary" @click="otpcheck"> Login</button>
          </div>
          <!-- Form Section -->
          <div v-show="formpage" class="steps clearfix">
            <div id="logoutpanel"><button type="button" class="btn btn-sm btn-success" @click="logout"><i class="fa fa-sign-out-alt"></i> logout</button>
              <!-- <button type="button" class="btn btn-sm btn-primary">Portal</button> -->
            </div>
            <div class="titleh" v-if="currentstage==1"> Personal Details</div>
            <div class="titleh" v-if="currentstage==2"> Educational Background</div> 
            <div class="titleh" v-if="currentstage==3"> Admission Sought for</div>
            <div class="titleh" v-if="currentstage==4"> Other Details</div>
            <div class="titleh" v-if="currentstage==5"> Documents & Signature</div>
            <div class="titleh" v-if="currentstage==6 && Mode=='APPROVE'"> Documents Approval</div>
            
            <ul role="tablist">
              <li role="tab" aria-disabled="false" :class="{ 'checked': currentstage>0 }" @click="currentstage=1" ><a id="wizard-t-0" aria-controls="wizard-p-0"> </a></li>
              <li role="tab" aria-disabled="false" :class="{ 'checked': currentstage>1 }" @click="currentstage=2" ><a id="wizard-t-1"  aria-controls="wizard-p-1"></a></li>
              <li role="tab" aria-disabled="false" :class="{ 'checked': currentstage>2 }" @click="currentstage=3" ><a id="wizard-t-2"  aria-controls="wizard-p-2"> </a></li>
              <li role="tab" aria-disabled="false" :class="{ 'checked': currentstage>3 }" @click="currentstage=4" ><a id="wizard-t-3"  aria-controls="wizard-p-3"> </a></li>
              <li role="tab" aria-disabled="false" :class="{ 'checked': currentstage>4 }" @click="currentstage=5" ><a id="wizard-t-4"  aria-controls="wizard-p-4"> </a></li>
              <li role="tab" aria-disabled="false" v-if="Mode=='APPROVE'" :class="{ 'checked': currentstage>5 }" @click="currentstage=6" ><a id="wizard-t-4"  aria-controls="wizard-p-4"> </a></li>
            </ul>

              <div class="profileprogress">
                Profile Completed {{profilecompleted}}%
                <div class="progress">                
                  <div class="progress-bar bg-success progress-bar-striped" :style="'width:'+profilecompleted+'%'"></div>
                </div>
              </div>
        
          </div>

          <h4></h4>
          <section v-if="currentstage==6 && Mode!='APPROVE'" >

            <h1>Thank you for submitting your application!</h1>
    <p>Our Application team will verify your application. <br/> Meanwhile you can Login to application portal to check your status!</p>
    <a href="#/login" class="button btn-info btn btn-sm">Application Portal</a>
 
            </section>
          <section v-if="currentstage==1">
            <div class="form-row">
              <div class="form-group col-md-4">
                <label for>
                  Title <span class="errtext">*</span>
                </label>
                <select class="form-control2" v-model="Title" id="Title" :onChange="validate1">
                  <option value="Mr">Mr</option>
                  <option value="Mrs">Mrs</option>
                  <option value="Ms">Ms</option>
                  <option value="Other">Other</option>
                </select>
              </div>
              <div class="form-group col-md-8">
                <label for>
                  Surname/Family Name <span class="errtext">*</span>
                </label>
                <input type="text" v-on:keyup="validate1" id="familyName" v-model="familyName" class="uppertext form-control2">
              </div>
             
            </div>

            <div class="form-row">
              <div class="form-group col-md-8">
              <label for>
                First Name/Given Names <span class="errtext">*</span>
              </label>
              <input type="text" id="Name" v-on:keyup="validate1" v-model="Name" class="uppertext form-control2">
              </div>
              <div class="col-md-4" v-if="ApplicantPhoto.path==''"><label for>
                  &nbsp;
                </label>
                <input type="file" ref="fileInputphoto" @change="handleProfilePhoto" hidden>
                <button type="button" id="ApplicantPhoto" class="Uploaddocbtn" @click="docfileclickphoto">Choose Profile Photo</button></div>
                <div class="errtext" id="applicatphoto"></div>
                <div class="col-md-4" v-if="ApplicantPhoto.path!=''">
                  <label for>
                  Profile Photo
                </label>
                  <button type="button"  @click="downloadFile(ApplicantPhoto.path)" class="btn btn-sm btn-info" ><i class="fa fa-download"></i></button>  <button class="btn btn-sm btn-danger" @click="deletedocprofile(ApplicantPhoto)" type="button" ><i class="fa fa-trash"></i></button>
                </div>
            </div>

            
            <div class="form-row">
              <div class="form-group col-md-12">
              <label for>
                If you changed
your name in the last
5 years please specify 
              </label>
              <input type="text" id="OldName" v-model="OldName" class="uppertext form-control2">
              </div>
              
              
            </div>

            <div class="form-row">
              <div class="form-group col-md-4">
                <label for>
                  Gender <span class="errtext">*</span>
                </label>
                <select class="form-control2" v-model="Gender" id="Gender" :onChange="validate1">
                  <option v-for="g in GenderList" :key="g" :value="g" >{{ g }}</option>
                </select>
              </div>
              <div class="form-group col-md-6">
                <label for>
                  Date of Birth  <span class="errtext">*</span>
                </label>
                <mydatepicker  :id="'DatepickerDOB'"  @myfunction="e => DOB=e"   :value="DOB" v-model="DOB" />
              </div>
              <div class="form-group col-md-2">
                <label for>
                  Age <span class="errtext">*</span>
                </label>
                <input type="text" :disabled="true" v-model="Age" class="form-control2">
              </div>
            </div>
           
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for>
                  Citizenship <span class="errtext">*</span>
                </label>
                <input type="text" v-on:keyup="validate1" v-model="Citizenship" id="Citizenship" placeholder="Enter Your Citizenship" class="form-control2" name="Citizenship">

             

            
              </div>
              <div class="form-group col-md-6">
                <label  for>
                  Email ID  <span class="errtext">*</span>
                </label>
                <input type="text" v-on:keyup="validate1" v-model="emailid" id="emailid" placeholder="Enter Your Email Id" class="form-control2" name="emailid">
                
               
              </div>
              
            </div>

            <div class="form-row">
              <div class="form-group col-md-3">
                <label for>
                  Country code
                </label>
                <select class="form-control2 country-code" v-model="countryCode" id="countryCode">
                  <option value='+886' class='option'>+886</option>
 <option value='+93' class='option'>+93</option>
 <option value='+355' class='option'>+355</option>
 <option value='+213' class='option'>+213</option>
 <option value='+1-684' class='option'>+1-684</option>
 <option value='+376' class='option'>+376</option>
 <option value='+244' class='option'>+244</option>
 <option value='+1-264' class='option'>+1-264</option>
 <option value='+672' class='option'>+672</option>
 <option value='+1-268' class='option'>+1-268</option>
 <option value='+54' class='option'>+54</option>
 <option value='+374' class='option'>+374</option>
 <option value='+297' class='option'>+297</option>
 <option value='+61' class='option'>+61</option>
 <option value='+43' class='option'>+43</option>
 <option value='+994' class='option'>+994</option>
 <option value='+1-242' class='option'>+1-242</option>
 <option value='+973' class='option'>+973</option>
 <option value='+880' class='option'>+880</option>
 <option value='+1-246' class='option'>+1-246</option>
 <option value='+375' class='option'>+375</option>
 <option value='+32' class='option'>+32</option>
 <option value='+501' class='option'>+501</option>
 <option value='+229' class='option'>+229</option>
 <option value='+1-441' class='option'>+1-441</option>
 <option value='+975' class='option'>+975</option>
 <option value='+591' class='option'>+591</option>
 <option value='+599' class='option'>+599</option>
 <option value='+387' class='option'>+387</option>
 <option value='+267' class='option'>+267</option>
 <option value='+47' class='option'>+47</option>
 <option value='+55' class='option'>+55</option>
 <option value='+246' class='option'>+246</option>
 <option value='+1-284' class='option'>+1-284</option>
 <option value='+673' class='option'>+673</option>
 <option value='+359' class='option'>+359</option>
 <option value='+226' class='option'>+226</option>
 <option value='+257' class='option'>+257</option>
 <option value='+238' class='option'>+238</option>
 <option value='+855' class='option'>+855</option>
 <option value='+237' class='option'>+237</option>
 <option value='+1' class='option'>+1</option>
 <option value='+1-345' class='option'>+1-345</option>
 <option value='+236' class='option'>+236</option>
 <option value='+235' class='option'>+235</option>
 <option value='+56' class='option'>+56</option>
 <option value='+86' class='option'>+86</option>
 <option value='+852' class='option'>+852</option>
 <option value='+853' class='option'>+853</option>
 <option value='+61' class='option'>+61</option>
 <option value='+61' class='option'>+61</option>
 <option value='+57' class='option'>+57</option>
 <option value='+269' class='option'>+269</option>
 <option value='+242' class='option'>+242</option>
 <option value='+682' class='option'>+682</option>
 <option value='+506' class='option'>+506</option>
 <option value='+385' class='option'>+385</option>
 <option value='+53' class='option'>+53</option>
 <option value='+599' class='option'>+599</option>
 <option value='+357' class='option'>+357</option>
 <option value='+420' class='option'>+420</option>
 <option value='+225' class='option'>+225</option>
 <option value='+850' class='option'>+850</option>
 <option value='+243' class='option'>+243</option>
 <option value='+45' class='option'>+45</option>
 <option value='+253' class='option'>+253</option>
 <option value='+1-767' class='option'>+1-767</option>
 <option value='+1-809,1-829,1-849' class='option'>+1-809,1-829,1-849</option>
 <option value='+593' class='option'>+593</option>
 <option value='+20' class='option'>+20</option>
 <option value='+503' class='option'>+503</option>
 <option value='+240' class='option'>+240</option>
 <option value='+291' class='option'>+291</option>
 <option value='+372' class='option'>+372</option>
 <option value='+268' class='option'>+268</option>
 <option value='+251' class='option'>+251</option>
 <option value='+500' class='option'>+500</option>
 <option value='+298' class='option'>+298</option>
 <option value='+679' class='option'>+679</option>
 <option value='+358' class='option'>+358</option>
 <option value='+33' class='option'>+33</option>
 <option value='+594' class='option'>+594</option>
 <option value='+689' class='option'>+689</option>
 <option value='+262' class='option'>+262</option>
 <option value='+241' class='option'>+241</option>
 <option value='+220' class='option'>+220</option>
 <option value='+995' class='option'>+995</option>
 <option value='+49' class='option'>+49</option>
 <option value='+233' class='option'>+233</option>
 <option value='+350' class='option'>+350</option>
 <option value='+30' class='option'>+30</option>
 <option value='+299' class='option'>+299</option>
 <option value='+1-473' class='option'>+1-473</option>
 <option value='+590' class='option'>+590</option>
 <option value='+1-671' class='option'>+1-671</option>
 <option value='+502' class='option'>+502</option>
 <option value='+44' class='option'>+44</option>
 <option value='+224' class='option'>+224</option>
 <option value='+245' class='option'>+245</option>
 <option value='+592' class='option'>+592</option>
 <option value='+509' class='option'>+509</option>
 <option value='+672' class='option'>+672</option>
 <option value='+39-06' class='option'>+39-06</option>
 <option value='+504' class='option'>+504</option>
 <option value='+36' class='option'>+36</option>
 <option value='+354' class='option'>+354</option>
 <option value='+91' class='option'>+91</option>
 <option value='+62' class='option'>+62</option>
 <option value='+98' class='option'>+98</option>
 <option value='+964' class='option'>+964</option>
 <option value='+353' class='option'>+353</option>
 <option value='+44' class='option'>+44</option>
 <option value='+972' class='option'>+972</option>
 <option value='+39' class='option'>+39</option>
 <option value='+1-876' class='option'>+1-876</option>
 <option value='+81' class='option'>+81</option>
 <option value='+44' class='option'>+44</option>
 <option value='+962' class='option'>+962</option>
 <option value='+7' class='option'>+7</option>
 <option value='+254' class='option'>+254</option>
 <option value='+686' class='option'>+686</option>
 <option value='+965' class='option'>+965</option>
 <option value='+996' class='option'>+996</option>
 <option value='+856' class='option'>+856</option>
 <option value='+371' class='option'>+371</option>
 <option value='+961' class='option'>+961</option>
 <option value='+266' class='option'>+266</option>
 <option value='+231' class='option'>+231</option>
 <option value='+218' class='option'>+218</option>
 <option value='+423' class='option'>+423</option>
 <option value='+370' class='option'>+370</option>
 <option value='+352' class='option'>+352</option>
 <option value='+261' class='option'>+261</option>
 <option value='+265' class='option'>+265</option>
 <option value='+60' class='option'>+60</option>
 <option value='+960' class='option'>+960</option>
 <option value='+223' class='option'>+223</option>
 <option value='+356' class='option'>+356</option>
 <option value='+692' class='option'>+692</option>
 <option value='+596' class='option'>+596</option>
 <option value='+222' class='option'>+222</option>
 <option value='+230' class='option'>+230</option>
 <option value='+262' class='option'>+262</option>
 <option value='+52' class='option'>+52</option>
 <option value='+691' class='option'>+691</option>
 <option value='+377' class='option'>+377</option>
 <option value='+976' class='option'>+976</option>
 <option value='+382' class='option'>+382</option>
 <option value='+1-664' class='option'>+1-664</option>
 <option value='+212' class='option'>+212</option>
 <option value='+258' class='option'>+258</option>
 <option value='+95' class='option'>+95</option>
 <option value='+264' class='option'>+264</option>
 <option value='+674' class='option'>+674</option>
 <option value='+977' class='option'>+977</option>
 <option value='+31' class='option'>+31</option>
 <option value='+687' class='option'>+687</option>
 <option value='+64' class='option'>+64</option>
 <option value='+505' class='option'>+505</option>
 <option value='+227' class='option'>+227</option>
 <option value='+234' class='option'>+234</option>
 <option value='+683' class='option'>+683</option>
 <option value='+672' class='option'>+672</option>
 <option value='+1-670' class='option'>+1-670</option>
 <option value='+47' class='option'>+47</option>
 <option value='+968' class='option'>+968</option>
 <option value='+92' class='option'>+92</option>
 <option value='+680' class='option'>+680</option>
 <option value='+507' class='option'>+507</option>
 <option value='+675' class='option'>+675</option>
 <option value='+595' class='option'>+595</option>
 <option value='+51' class='option'>+51</option>
 <option value='+63' class='option'>+63</option>
 <option value='+870' class='option'>+870</option>
 <option value='+48' class='option'>+48</option>
 <option value='+351' class='option'>+351</option>
 <option value='+1' class='option'>+1</option>
 <option value='+974' class='option'>+974</option>
 <option value='+82' class='option'>+82</option>
 <option value='+373' class='option'>+373</option>
 <option value='+40' class='option'>+40</option>
 <option value='+7' class='option'>+7</option>
 <option value='+250' class='option'>+250</option>
 <option value='+262' class='option'>+262</option>
 <option value='+590' class='option'>+590</option>
 <option value='+290' class='option'>+290</option>
 <option value='+1-869' class='option'>+1-869</option>
 <option value='+1-758' class='option'>+1-758</option>
 <option value='+590' class='option'>+590</option>
 <option value='+508' class='option'>+508</option>
 <option value='+1-784' class='option'>+1-784</option>
 <option value='+685' class='option'>+685</option>
 <option value='+378' class='option'>+378</option>
 <option value='+239' class='option'>+239</option>
                  <!-- Add more country codes here -->
                </select>
              </div>
              <div class="form-group col-md-4">
                <label for>
                  Telephone (Home)
                </label>
                <input type="number" v-model="Telephoneh" id="Telephoneh" class="form-control2">
              </div>
              <div class="form-group col-md-5">
                <label for>
                  Telephone (Mobile) <span class="errtext">*</span>
                </label>
                <input type="number" v-on:keyup="validate1" v-model="Telephonem" id="Telephonem" class="form-control2">
              </div>
              
            </div>

            
            <div class="form-row">
              <label for>
                Permanent Addres <span class="errtext">*</span> (including Postal code/Zip code)
              </label>
              <textarea id="PermanentAddress" v-on:keyup="validate1" v-model="PermanentAddress" class="form-control2"></textarea>
            </div>

            <div class="form-row">
              <label for>
                Mailing Address <span class="errtext">*</span> (If different from Permanent address, Please include postal code/Zip code)
              </label>
              <textarea id="MailingAddress" v-on:keyup="validate1" v-model="MailingAddress" class="form-control2"></textarea>
            </div>

            <div class="form-row">
              <label for>
                Home address of the emergency contact (If different from above)
              </label>
              <textarea id="HomeAddress" v-model="HomeAddress" class="form-control2"></textarea>
            </div>


            <div class="form-row">
              <div class="form-group col-md-6">
                <label  for>
                  Person to contact in
case of Emergency  <span class="errtext">*</span>
                </label>
               
                
                
                <input type="text" v-on:keyup="validate1" v-model="EmergencyName"  placeholder="Enter Name of the Person" class="form-control2" id="EmergencyName" name="EmergencyName">
                
              
          
              </div>
              

              <div class="form-group col-md-6">
                <label  for>
                  Relationship of the
emergency contact  <span class="errtext">*</span>
                </label>
                <select class="form-control2" :onChange="validate1"  v-model="ECRelationship" id="ECRelationship">
                  <option value='Father'>Father</option>
                  <option value='Mother'>Mother</option>
                  <option value='Grandfather'>Grandfather</option>
                  <option value='Grandmother'>Grandmother</option>
                  <option value='Uncle'>Uncle</option>
                  <option value='Aunt'>Aunt</option>
                  <option value='Cousin'>Cousin</option>
                  <option value='Husband'>Husband</option>
                  <option value='Wife'>Wife</option>
                  <option value='Guardian'>Guardian</option>
                  <option value='Others'>Others</option>
                </select>
              </div>
           
            </div>



            <div class="form-row">
              <div class="form-group col-md-6">
                <label  for>
                  Telephone number
of emergency contact <span class="errtext">*</span>
                </label>
               
                
                
                <input type="number" v-on:keyup="validate1" v-model="EmergencyTelephone" id="EmergencyTelephone"  placeholder="Enter Telephone number" class="form-control2" name="State">
                
          
          
              </div>
              

              <div class="form-group col-md-6">
                <label  for>
                  Email ID of the
emergency contact <span class="errtext">*</span>
                </label>
                <input type="text" v-on:keyup="validate1" v-model="EmergencyEmail" id="EmergencyEmail" placeholder="Enter Email ID" class="form-control2" name="State">
                
              
          
                        
           
              </div>
           
            </div>


            <div class="form-row">
              <div class="form-group col-md-6">
                <label for>
                  Name of the Father/
Guardian if a minor 
                </label>
                <input type="text"  id="fathernameminor" v-model="fathernameminor" class="form-control2">
              </div>
              <div class="form-group col-md-6">
                <label for>
                  Email of Father/
Guardian if a minor  
                </label>
                <input type="email" id="emailidminor" v-model="emailidminor" class="form-control2">
              </div>
              
            </div>


            <div class="form-row">
              <div class="form-group col-md-6">
                <label for>
                  Telephone number of
Father/Guardian if a minor 
                </label>
                <input type="text" id="telephoneminor" v-model="telephoneminor" class="form-control2">
              </div>
              <div class="form-group col-md-6">
                <label for>
                  Name of the Mother/
Guardian if a minor  
                </label>
                <input type="text"  id="mothernameminor" v-model="mothernameminor" class="form-control2">
              </div>
              
            </div>

            <div class="form-row">
              <div class="form-group col-md-6">
                <label for>
                  Email of Mother/Guardian
if a minor 
                </label>
                <input type="text" id="Motheremailminor" v-model="Motheremailminor " class="form-control2">
              </div>
              <div class="form-group col-md-6">
                <label for>
                  Telephone number of
Mother/Guardian if a minor  
                </label>
                <input type="number"  id="telephonemotherminor" v-model="telephonemotherminor" class="form-control2">
              </div>
              
            </div>

           
            <button style="float: right;" @click="next()" type="button" class="btn btn-info">Save & Next</button>
          </section>

          <h4></h4>
          <section  v-if="currentstage==2"> 
            <p>Qualifying institutions</p>
            <div class="qalificationbox" v-for="(q,index)  in Qualifying" :key="q">
            <div class="row" >
                <div class="col-md-12">Institution : {{ q.Institution }} ({{q.InstitutionAddress}})</div>
            </div>
            
            <div class="row" >
              <div class="col-md-6">Start Date: {{ q.attstartdate }}</div>
                <div class="col-md-6">End Date: {{ q.attenddate }}</div>
                
              
            </div>
            <div class="row" >
              <div class="col-md-6">Degree: {{ q.Degree }}</div>
                <div class="col-md-6">Completion: {{ q.Completiondate }}</div>
                 
                <div style="float: right;
    width: 100%;
    text-align: right;
    margin-right: 10px;" @click="removeqa(index)"><i class="fa-solid fa-trash"></i></div>
            </div>
          
           
          </div>


            <div class="form-row">
              <label for>
                Name Of the Institution <span class="errtext">*</span>
              </label>
              <input v-model="Institution" v-on:keyup="qavalidate" id="Institutionfield" type="text" class="form-control2">
            </div>
            <div class="form-row">
              <label for>
                Address Of the Institution <span class="errtext">*</span>
              </label>
              <input v-model="InstitutionAddress" v-on:keyup="qavalidate" id="InstitutionAddress" type="text" class="form-control2">
            </div>


            <div class="form-row">
              <div class="form-group col-md-6">
                <label for>
                  Attendance Start Date <span class="errtext">*</span> 
                </label>
                <mydatepicker  :id="'Datepickerstart'"  @myfunction="e => attstartdate=e"   :value="attstartdate" v-model="attstartdate" />
                  <p id="attstartdate" class="errtext"></p>
              </div>
              <div class="form-group col-md-6">
                <label for>
                  Attendance End Date <span class="errtext">*</span>
                </label>
                <mydatepicker  :id="'Datepickerend'"  @myfunction="e => attenddate=e"   :value="attenddate" v-model="attenddate" />
                  <p id="attenddate" class="errtext"></p>
              </div>
              </div>

              <div class="form-row">
              <div class="form-group col-md-6">
                <label for>
                  Degree <span class="errtext">*</span>
                </label>
                <input v-model="Degree" id="Degree" v-on:keyup="qavalidate" type="text" class="form-control2">
              </div>
              <div class="form-group col-md-6">
                <label for>
                  Completion/expected Date <span class="errtext">*</span>
                </label>
                <mydatepicker  :id="'Completiondate'"  @myfunction="e => Completiondate=e"   :value="Completiondate" v-model="Completiondate" />
                  <p id="Completiondate" class="errtext"></p>
              </div>
              



              <div style="text-align: center; width: 100%;"> <button @click="addmoreQualifying" type="button" class="btn btn-sm btn-success" >Add to Educational list</button></div>
              
            </div>
            <button style="float: left;" @click="back()" type="button" class="btn btn-secondary">Back</button>
            <button style="float: right;" @click="next()" type="button" class="btn btn-info">Save & Next</button>
          </section>

          <h4></h4>
          <section  v-if="currentstage==3"> 
            <h3><strong>(TICK THE APPROPRIATE AREA) </strong> <span class="errtext">*</span></h3>
            <p>(If unsure, leave this blank)</p>
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for style="line-height:40px">
                  
                <input type="radio" name="Program" v-model="selectedProgram" value="4MD"  class="mycheckbx form-control2" />4-Year MD Program 
              </label>
              </div>
              <div class="form-group col-md-6">
                <label for style="line-height:40px">
                  
                <input type="radio" name="Program" v-model="selectedProgram" value="5MD"  class="mycheckbx form-control2" />5-Year MD Program 
              </label>
              </div>
             
              </div>

              <div class="errtext" id="selectedP"></div>
              <div class="form-row">
              <div class="form-group col-md-12">
                <label for style="line-height:40px">
                  
                <input type="radio" name="selectedSubProgram" v-model="selectedSubProgram" value="PremedicalProgram"  class="mycheckbx form-control2" />Premedical Program
              </label>
              <p>For those who are seeking admission after completion
of high school or who have completed some PM program
or college-level education elsewhere</p>
              </div>

            
              </div>

              <div class="form-row">
              <div class="form-group col-md-12">
                <label for style="line-height:40px">
                  
                <input type="radio" name="selectedSubProgram" v-model="selectedSubProgram" value="ScienceProgram"  class="mycheckbx form-control2" />Basic Science Program
              </label>
              <p>For those who have completed a PM program at
                another medical school or who have bachelor’s degree</p>
              </div>
              </div>

              <div class="form-row">
              <div class="form-group col-md-12">
                <label for style="line-height:40px">
                  
                <input type="radio" name="selectedSubProgram" v-model="selectedSubProgram" value="ClinicalProgram"   class="mycheckbx form-control2" />Clinical Science Program
              </label>
              <p>For those who are transferring from another medical
                school after completing minimum of 2 years of education</p>
              </div>
              </div>
              <p style="color: black;">List the year and semester, admission is being sought for</p>
              <div class="form-row">
              <div class="form-group col-md-6">
                <label for>
                  Semester <span class="errtext">*</span>
                </label>
                <select class="form-control2" v-model="Semester" :onChange="validate3" id="Semester">
                  <option value="Spring-Jan">Spring-Jan</option>
                  <option value="Summer-May">Summer-May</option>
                  <option value="Summer-Sept">Fall-Sept</option>
                </select>
              </div>
              <div class="form-group col-md-6">
                <label for>
                  Year <span class="errtext">*</span>
                </label>
                <input v-model="Degreeyear" v-on:keyup="validate3" id="Degreeyear" type="number" class="form-control2">
              </div>
              </div>
            <button style="float: left;" @click="back()" type="button" class="btn btn-secondary">Back</button>
            <button style="float: right;" @click="next()" type="button" class="btn btn-info">Save & Next</button>
          </section>


          <h4></h4>
          <section v-if="currentstage==4">
          
            <div>
              <h3>TRANSFER CREDITS, IF APPLICABLE</h3>
              <table class="mytable">
                  <tr>
                  <th>Name and Address of the Institution</th>
                  <th>Courses completed and passed at the previous institution</th>
                  </tr>
                <tr>
                  <td><textarea class="form-control2" v-model="trInstitution1" ></textarea></td>
                  <td><textarea class="form-control2"  v-model="ccInstitution1" ></textarea></td>
                </tr>
                <tr>
                  <td><textarea class="form-control2"  v-model="trInstitution2" ></textarea></td>
                  <td><textarea class="form-control2"  v-model="ccInstitution2" ></textarea></td>
                </tr>
                <tr>
                  <td><textarea class="form-control2"  v-model="trInstitution3" ></textarea></td>
                  <td><textarea class="form-control2"  v-model="ccInstitution3" ></textarea></td>
                </tr>
                <!-- <tr>
                  <td><textarea class="form-control2"  v-model="trInstitution4" ></textarea></td>
                  <td><textarea class="form-control2"  v-model="ccInstitution4" ></textarea></td>
                </tr> -->
              </table>
            </div>
            <h3>DISCIPLINE INFORMATION</h3>

            <div class="form-row">
              <div class="form-group col-md-12">
                <label for>
                  Have you ever been placed on probation, suspended, removed, dismissed or expelled from any school or academic program since 9th grade? *
                </label>
                <select class="form-control2" v-model="ninethgrad">
                  <option value="No">No</option>
                  <option value="Yes">Yes</option>
                </select>
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-md-12">
                <label for>
                  Other than traffic offenses, have you ever been convicted of any misdemeanor, felony, or other crime? *
                </label>
                <select class="form-control2"  v-model="convicted">
                  <option value="No">No</option>
                  <option value="Yes">Yes</option>
                </select>
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-md-12">
                <label for>
                  If you answered yes to either question, please provide an explanation and the approximate dates of each incident. Please attach your response to the end of the application. *
                </label>
               <textarea class="form-control2" id="incidentdetails" v-model="incidentdetails" ></textarea>
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-md-12">
                <label for>
                  HOW DID YOU HEAR ABOUT METROPOLITAN UNIVERSITY?
                </label>
               <textarea class="form-control2" v-model="howyouknow" ></textarea>
              </div>
            </div>

            <button style="float: left;" @click="back()" type="button" class="btn btn-secondary">Back</button>
            <button style="float: right;" @click="next()" type="button" class="btn btn-info">Save & Next</button>
          </section>

          <h4></h4>
          <section v-if="currentstage==5">
            

            <table class="table" >
              <tr>
                <th>Name Of the Document</th>
                <th>Action</th>
              </tr>

              <tr v-for="f in Docs" :key="f">
                <td>{{ f.docname }} <span v-if="f.isrequired" class="errtext">*</span></td>
                <td v-if="f.path!=''"><button class="btn btn-sm btn-danger" @click="deletedoc(f)" type="button" ><i class="fa fa-trash"></i></button> <button type="button"  @click="downloadFile(f.path)" class="btn btn-sm btn-info" ><i class="fa fa-download"></i></button> </td>
                <td v-if="f.path==''"><button type="button" class="Uploaddocbtn" @click="docfileclick(f.docname)">Choose file to Upload</button></td>
              </tr>
            </table>
          
           
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for>
                
                </label>
              
              </div>

           
              <div class="form-group col-md-6">
                <label for>
                  &nbsp;
                </label>
                <input type="file" ref="fileInput" @change="handleFileChange" hidden>
                
              </div>
              
            </div>
              <div id="docerror" class="errtext"></div>
            <hr/>
            <h3>Signatures <span style="font-size: 10px;"> (Draw your Signature / Upload your Signature Copy / Digital Signature )</span></h3>
            <div class="form-row">
              <label for>
                <input type="checkbox" style="
    float: left;
    width: 30px;
    margin-right: 10px;
" v-model="digitalsign" class="form-control2"/> (By clicking this checkbox, hereby declare that the above information is true to the best of my knowledge)
              </label>
            
              
            </div>


            <div class="form-row" v-if="!digitalsign">
              <label for>
                Signature of the Applicant   * 
              </label>
              <vueSignature
                :ref="'signature'"
                id="signature"
                :sigOption="option"
                :w="'800px'"
                :h="'150px'"
                :disabled="disabled"
              ></vueSignature>
              <button type="button" class="clearbtn" @click="clear">Clear</button>
              <input id="signuploadUpload"  ref="signuploadUpload" @change="handlesignuploadUpload" type="file" hidden>
              <button type="button" class="Uploadsingbtn" @click="signuploadUpload">Upload Your Own</button>
              
            </div>
            <p id="signatureerr" class="errtext" ></p>
           
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for>
                  Place *
                </label>
               <input type="text" v-model="Place" id="Place" class="form-control2" />
              </div>

              

              

     
              
              <div class="form-group col-md-6">
                <label for>
                  Date *
                </label>
               <input type="text" v-model="currentdate" :disabled="true" class="form-control2" />
              </div>
              
            </div>

           
            <button style="float: left;" @click="back()" type="button" class="btn btn-secondary">Back</button>
            <button  style="float: left;" @click="submitapplication(true)" type="button" class="btn btn-primary">Preview</button>
            <button style="float: right;" @click="submitapplication()" type="button" class="btn btn-success" v-if="ApplicationStatus!='approved'">Submit the Application</button>
            <!-- <button style="float: left;" @click="PayNow()" type="button" class="btn btn-success" >Pay Now</button> -->
            
            <div style="text-align: center;
    padding-top: 70px;"><button v-if="ApplicationStatus!=''" @click="DownloadFilledApp()" type="button" class="btn btn-info">Download Application Form</button>
   
            
  
  </div>
            
          </section>

          <h4></h4>
          <section v-if="currentstage==6 && Mode=='APPROVE'">
            <p>Check List</p>
            <div class="form-row">
              <div class="form-group col-md-10">
                <label for>
                  Applicant Photo *
                </label>
              </div>

              <div class="form-group col-md-2">
                <label for>
                  
                </label>
                <input type="checkbox" v-model="ApplicantPhotoverified" class="form-control" />
              </div>
              
            </div>

            <div class="form-row">
              <div class="form-group col-md-10">
                <label for>
                  Mandatory Transcripts *
                </label>
              </div>

              <div class="form-group col-md-2">
                <label for>
                  
                </label>
                <input type="checkbox" v-model="mandatorytranscriptsverified" class="form-control" />
              </div>
              
            </div>

            <div class="form-row">
              <div class="form-group col-md-10">
                <label for>
                  Mandatory Details *
                </label>
              </div>

              <div class="form-group col-md-2">
                <label for>
                  
                </label>
                <input type="checkbox" v-model="mandatorydetailsverified" class="form-control" />
              </div>
              
            </div>

            <div class="form-row">
              <div class="form-group col-md-10">
                <label for>
                  Total Documents *
                </label>
              </div>

              <div class="form-group col-md-2">
                <label for>
                  
                </label>
                <input type="text" v-model="TotalDocuments" class="form-control2" />
              </div>
              
            </div>

          

            <div class="form-row">
              <div class="form-group col-md-12">
                <label for>
                  University Regn. No *
                </label>
              </div>
              <input type="text" class="form-control2" v-model="UniversityRegnNo" />
              
            </div>

            <div class="form-row">
              <div class="form-group col-md-12">
                <label for>
                  Roll No *
                </label>
              </div>
              <input type="text" class="form-control2" v-model="RollNo" />
              
            </div>
            
            <button style="float: right;"  v-if="ApplicationStatus=='submitted'" @click="Approveapplication()" type="button" class="btn btn-success">Approve</button>
            <div style="float:right" v-if="ApplicationStatus=='approved'">Application Already Approved</div>
            <button v-if="ApplicationStatus!=''" style="float: left;" @click="DownloadFilledApp()" type="button" class="btn btn-info">Download Application Form</button>
            
          </section>
        </div>
      </form>
     
    </div>


    <transition name="modal" v-show="ShowTable">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-container" style="height: 100%;">
              <div>
                <slot name="header">
                  <div class="top-navbar">
                    <nav class="navbar navbar-light justify-content-between" style="padding:0px">
                      <h1><a class="navbar-brand">Application Preview</a></h1>
                      <form class="form-inline">
                        <button type="button" class="btn btn-danger" @click="ShowTable=false" ><i class="far fa-times-circle"></i></button>                        
                      </form>
                    </nav>
                  </div>
                </slot>
              </div>

              <div class="modal-body" style="padding:0px;">
                <h3 v-if="loadingpdf">loading...</h3>
                <iframe id="pdfIframe" src="" frameborder="0" style="width: 100%;height: 600px;"></iframe>
              </div>
            </div>
          </div>
        </div>
      </transition> 

  
    <transition name="error">
      <div id="maxalertclient" v-if="maxalert"> {{ max }} <i class="far fa-times-circle" @click="maxalert = false"></i></div>
    </transition>
  </div>
</template>

<script>
import helper from "../helper.js"
//import { VueReCaptcha } from 'vue-recaptcha-v3';
//import { DropDownList } from '@progress/kendo-vue-dropdowns';
// import { DatePicker } from '@progress/kendo-vue-dateinputs';
import '@progress/kendo-theme-default/dist/all.css';
//import { MultiSelect } from '@progress/kendo-vue-dropdowns';
import swal from 'sweetalert';
//import Recaptcha from '../components/Recaptcha.vue'
import Mydatepicker from '../components/Mydatepicker.vue'
import { filterBy } from '@progress/kendo-data-query';
import moment from "moment"
import $ from 'jquery';
import vueSignature from "vue-signature";
import CryptoJS from 'crypto-js';


export default {
  components: {
    //'dropdownlist': DropDownList,
    // 'datepicker': DatePicker,
    // 'multiselect': MultiSelect,
    //Recaptcha,
    //'vue-recaptcha':VueReCaptcha,
    Mydatepicker,
    vueSignature
  },
  data() {
    return {
      ShowTable:false,
      loadingpdf:false,
      nextclicked1:false,
      nextclicked2:false,
      nextclicked3:false,
      nextclicked4:false,
      nextclicked5:false,
      captchaToken:"",
      loginbtnclicked:false,
      UserPassword:"",
      UserConfirmPassword:"",
      iscreateaccount:false,
      option: {
        penColor: "rgb(0, 0, 0)",
        backgroundColor: "rgb(255,255,255)",
      },
      disabled: false,
      Useremail:"",
      dataUrl: "http://localhost:8081/img/signpad.png",
      login: true,
      formpage:false,
      OTPpage:false,
      sessionid:"",
      currentstage:0,
      Isemailexists: false,
      imnotroboot: false,
      PageLoading: false,
      CloseFlag: false,

      Title :"",
familyName:"",
Name:"",
OldName:"",
Citizenship:"",
emailid:"",
Telephoneh:"",
Telephonem:"",
countryCode:"",
EmergencyName:"",
ECRelationship:"",
PermanentAddress:"",
MailingAddress:"",
HomeAddress:"",
EmergencyTelephone:"",
EmergencyEmail:"",
fathernameminor:"",
emailidminor:"",
telephoneminor:"",
mothernameminor:"",
Motheremailminor:"",
telephonemotherminor:"",

Degreeyear:"",
Semester:"",
ClinicalProgram:false,
ScienceProgram:false,
PremedicalProgram:false,
PGProgram:false,
MDProgram:false,
selectedProgram:"",
selectedSubProgram:"",

trInstitution1 : "",
            ccInstitution1 :"",

            trInstitution2 : "",
            ccInstitution2 : "",

            trInstitution3 : "",
            ccInstitution3 : "",

            trInstitution4 : "",
            ccInstitution4 : "",
            ninethgrad : "No",
            convicted  : "No",
            incidentdetails : "",
            howyouknow:"",


      MailId: "",
      mobilenumber: "",
      ApplicantPhoto:{
        docname:"",
        path:"",
      },
      Age:"",
      otp:"",
      WorkLocation: "",
      Qualification: "",
      JobCategory: "",
      Designation: "",
      Experience: "",
      JobAppliedfor: "",
      Gender: "",
      IsActive: true,
      maxalert: false,
      ApplicationStatus:"",
      max: 0,
      Mode: "NEW",
      Qualifying: [],
      digitalsign:false,
      Particular:"SSLC",
      yearpassed:"",
      regno:"",
      markscored:"",
      waitingforresult:false,
      totalmark:"",
      markpre:"",
      Institution:"",
      InstitutionAddress:"",
      attstartdate:"",
      attenddate:"",
      Degree:"",
      Completiondate:"",
      Medium:"",
      Attempts:"",
      DOB: "",
      MotherTongue:"Tamil",
      Community:"",
      Caste:"",
      WhatsAppNo:"",
      AadharNo:"",
      Religion:"",
      CommunicationAddress:"",
      Post:"",
      Taluk:"",
      District:"",
      Pincode:"",
      FatherName:"",
      Occupation:"",
      MotherName:"",
      AnnualIncome:"",
      Nationality:"Indian",
      State:"Tamilnadu",
      currentdate:"",
      today:"",
      Place:"",
      Signatureby:"",
      GuardianName:"",
      IPaddress:"",
      
      SSLCTamilq1:'',
     
      SSLCTamilMY:'',
      SSLCEnglishq1:'',
     
      SSLCEnglishMY:'',
      SSLCMathq1:'',
     
      SSLCMathMY:'',
      SSLCScienceq1:'',
     
      SSLCScienceMY:'',
      SSLCSocialsq1:'',
     
      SSLCSocialsMY:'',
      HSCTamilq1:'',
      
      HSCTamilMY:'',
      HSCEnglishq1:'',
      
      HSCEnglishMY:'',
      HSCPhysicsq1:'',
      
      HSCPhysicsMY:'',
      HSCChemistryq1:'',
      
      HSCChemistryMY:'',
      HSCMathq1:'',
      
      HSCMathMY:'',
      HSCBioq1:'',
      
      HSCBioMY:'',
      NameVocational:'',
      HSCtq1:'',
      
      HSCtMY:'',
      HSCPractical1q1:'',
      
      HSCPractical1MY:'',
      HSCPractical2q1:'',
      
      HSCPractical2MY:'',

      ExtraCurricular :[],
      SportsGames:"",
      National:"",
      ECState:"",
      Devision:"",
      ECDistrict:"",

      PhysicalIdentification1:"",
PhysicalIdentification2:"",
Transport:"",
admittingyear:"",
Branch:"",
Route:"",
Stage:"",
BoardingPoint:"",
Hostel:"",
BloodGroup:"",
exserviceman:"",
exservicemanNo:"",
exservicemanRank:"",
firstgraduate:"",
singleChild:"",
differentlyabled:"",
NSSYRC:"",

docname:"Applicant Photo",
Docs:[{
  docname:"Premedical/bachelor/ undergraduate/ 12th grade",
  path:"",
  isrequired:false
},{
  docname:"11th grade",
  path:"",
  isrequired:false
},{
  docname:"Other professional transcripts",
  path:"",
  isrequired:false
},{
  docname:"For any other helpful transcripts",
  path:"",
  isrequired:false
},{
  docname:"CV",
  path:"",
  isrequired:true
},{
  docname:"Any exam results - Marksheet MCAT/NEET",
  path:"",
  isrequired:false
}],
ApplicantSign:"",
ParentSign:"",
applicationID:"",
profilecompleted:0,

RollNo:"",
UniversityRegnNo:"",
Section:"",
TotalDocuments:0,
mandatorydetailsverified:"",
mandatorytranscriptsverified:"",
ApplicantPhotoverified:"",
Othersdoc:false,
SCorSTBankAccNodoc:false,
ConductCertificatedoc:false,
CommunityCertificatedoc:false,
TransferCertificatedoc:false,
SCorSTIncomeCertificatedoc:false,
SCorSTAttendanceCertificatedoc:false,
sslcdoc:false,

      Candidatecommands: "",
      file_max_size: 1064000,
      GenderList: [],
      Branchmaster:[],
      QualificationList: ["Other"],
      QualificationListMain: ["Other"],
      ExperienceList: ["Other"],
      WorkLocationList: ["Other"],
      MainWorkLocationList: ["Other"],
      PreferredLocationList: [],
      PreferredLocation: [],
      DesignationList: [],
      JobCategoryList: ["Other"],
      JobCategoryListMain: ["Other"],
      isadd: false,
      errorFlag: false,
      isotherReligion: false,
      isotherState: false,
      isotherNationality:false,
      IsQualification: false,
      IsExperience: false,
      IsJobCategory: false,
      SkipOTP:false,
    };
  },
  mounted() {

    //this.generateCaptcha();
    //     $("#wizard").steps({
    //     headerTag: "h4", bodyTag: "section", transitionEffect: "fade", enableAllSteps: true, transitionEffectSpeed: 500, onStepChanging: function (event, currentIndex, newIndex) {
    //         if (newIndex === 1) { $('.steps ul').addClass('step-2'); } else { $('.steps ul').removeClass('step-2'); }
    //         if (newIndex === 2) { $('.steps ul').addClass('step-3'); } else { $('.steps ul').removeClass('step-3'); }
    //         if (newIndex === 3) { $('.steps ul').addClass('step-4'); $('.actions ul').addClass('step-last'); } else { $('.steps ul').removeClass('step-4'); $('.actions ul').removeClass('step-last'); }
    //         return true;
    //     }, labels: { finish: "Place Holder", next: "Next", previous: "Previous" }
    // }); $('.wizard > .steps li a').click(function () { $(this).parent().addClass('checked'); $(this).parent().prevAll().addClass('checked'); $(this).parent().nextAll().removeClass('checked'); }); $('.forward').click(function () { $("#wizard").steps('next'); })
    // $('.backward').click(function () { $("#wizard").steps('previous'); })
    // $('.checkbox-circle label').click(function () { $('.checkbox-circle label').removeClass('active'); $(this).addClass('active'); })

  },
  created: function () {
    
    var today = new Date()
    this.today = today
    this.currentdate = today.getDate()+"-"+(today.getMonth()+1)+"-"+today.getFullYear()
    this.genderList();
    this.branchList();
    this.setip();
    const paramValue = this.getQueryParamValue('Param');
    console.log("paramValue===>",paramValue)
    if(paramValue!="" &&   paramValue!=null){
      var splitstr = atob(paramValue).split("||")
      this.Mode =splitstr[0]
      if (this.Mode=="NEW"){
        this.SkipOTP=true
        this.sessionid = splitstr[2]
      } else {
        this.Useremail = splitstr[1]
        this.sessionid = splitstr[2]
        this.login=false
        this.OTPpage=false
        this.formpage=true
        this.currentstage=1
        this.loaduser()
      }
    }

  },
  watch: {
    selectedSubProgram(newval) {
      if (newval) {
        this.validate3()
      }
    },
    Completiondate(newval) {
      if (newval) {
        this.qavalidate()
      }
    },
    attenddate(newval) {
      if (newval) {
        this.qavalidate()
      }
    },
    attstartdate(newval) {
      if (newval) {
        this.qavalidate()
      }
    },

    currentstage(newval, oldval) {
      if (oldval != newval && newval==5) {  
        var self = this
        setTimeout(function() {
        if(self.ApplicantSign!="")
          self.$refs.signature.fromDataURL(self.ApplicantSign);
        if(self.ParentSign!="")
          self.$refs.signature1.fromDataURL(self.ParentSign);
        }
        , 500);

        

      }
    }, 
    DOB(newval, oldval) {
      if (oldval != newval) {
        const parts = newval.split("-");
        const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
        var temp = new Date(formattedDate)
        this.Age = this.calculateAge(temp)
        this.validate1();
      }
    },
    MotherTongue(newval, oldval) {
      if (oldval != newval) {
        if (newval=="Others"){
          this.MotherTongue = ""
        }
      }
    },
    Religion(newval, oldval) {
      if (oldval != newval) {
        if (newval=="Others"){
          this.isotherReligion = true
          this.Religion=""
        }
      }
    },
    State(newval, oldval) {
      if (oldval != newval) {
        if (newval=="Others"){
          this.isotherState = true
          this.State=""
        }
      }
    },
    Nationality(newval, oldval) {
      if (oldval != newval) {
        if (newval=="Others"){
          this.isotherNationality = true
          this.Nationality=""
        }
      }
    },
    waitingforresult(newval) {
      if (newval) {
        this.totalmark = 0
        this.markscored = 0
      }
    },
    markscored(newval, oldval) {
      if (oldval != newval) {
        const percentage = (newval / this.totalmark) * 100;
        console.log("percentage-->",percentage)
        if (this.totalmark>-1 && newval>-1){
          this.markpre = percentage
        }
      }
    },
    totalmark(newval, oldval) {
      if (oldval != newval) {
        const percentage = (this.markscored/newval ) * 100;
        console.log("percentage-->2",percentage)
        if (this.markscored>-1 && newval>-1){
          this.markpre = percentage
        }
      }
    },

  },

  methods: {
    logout(){
      this.login=true
      this.OTPpage=false
      this.formpage=false
      this.currentstage=0
      this.Useremail = ""
      this.iscreateaccount=false
    },
    onCaptchaVerified(token) {
      this.captchaToken = token;
      console.log('Captcha verified:', token);
    },
    setip(){
      fetch('https://api.ipify.org?format=json')
        .then(x => x.json())
        .then(({ ip }) => {
            this.IPaddress = ip;
        });
    },
    getQueryParamValue(param){
      const query = window.location.search.substring(1);
      const queryParams = new URLSearchParams(query);
      return queryParams.get(param);
    },
    DownloadFilledApp(){
        window.open(helper.SERVICEURL+"filledpdf?username="+this.Useremail, '_blank');
    },  
    docfileclickphoto(){
      this.$refs.fileInputphoto.click();
    },
    docfileclick(dname){
      console.log("comingdocfileclick")
      this.docname = dname
      this.$refs.fileInput.click();
    },
    handlesignuploadUpload(){
      var files = this.$refs.signuploadUpload.files[0];
      var self = this
      const reader = new FileReader();
        reader.onloadend = () => {
          var base64String = reader.result.split(',')[1];
          self.$refs.signature.fromDataURL("data:image/png;base64,"+base64String);
        };
        reader.readAsDataURL(files);
    },
    signuploadUpload(){
      this.$refs.signuploadUpload.click();
    },
    handlesignuploadUpload2(){
      var files = this.$refs.signuploadUpload2.files[0];
      var self = this
      const reader = new FileReader();
        reader.onloadend = () => {
          var base64String = reader.result.split(',')[1];
          self.$refs.signature1.fromDataURL("data:image/png;base64,"+base64String);
        };
        reader.readAsDataURL(files);
    },
    signuploadUpload2(){
      this.$refs.signuploadUpload2.click();
    },
    Approveapplication(){
        
      
      
      var validation = true
      if(validation) {

        var param = {
            username : this.Useremail,
            RollNo : this.RollNo,
            UniversityRegnNo:this.UniversityRegnNo,
            Section:this.Section,
            TotalDocuments:this.TotalDocuments,
            mandatorydetailsverified:this.mandatorydetailsverified,
            mandatorytranscriptsverified:this.mandatorytranscriptsverified,
            ApplicantPhotoverified:this.ApplicantPhotoverified,
            Othersdoc:this.Othersdoc,
            SCorSTBankAccNodoc:this.SCorSTBankAccNodoc,
            ConductCertificatedoc:this.ConductCertificatedoc,
            CommunityCertificatedoc:this.CommunityCertificatedoc,
            SCorSTIncomeCertificatedoc:this.SCorSTIncomeCertificatedoc,
            SCorSTAttendanceCertificatedoc:this.SCorSTAttendanceCertificatedoc,
            TransferCertificatedoc:this.TransferCertificatedoc,
            sslcdoc:this.sslcdoc,
          }
       
           
            param.ApplicationStatus ="approved",
            param.applicationID=this.applicationID
        
          if(this.saveapplication(param)){
              this.ApplicationStatus = "approved"
              alert("Application Approved Successfully!")
            return false
          } else {
            this.errorFlag = true
            return this.error("Something Went Wrong!")
          }
      }

     
        
    },
    async encryptData(data, key) {
    const encodedData = new TextEncoder().encode(data);
    const iv = window.crypto.getRandomValues(new Uint8Array(12));
    const encryptedData = await window.crypto.subtle.encrypt(
      {
        name: "AES-GCM",
        iv: iv,
      },
      key,
      encodedData
    );
    return { encryptedData, iv };
  },
  ency(pgid){
    var aeskey ="1300011789605020"
    var encryptedData= CryptoJS.AES.encrypt(pgid.toString(), aeskey).toString();
    return encryptedData
  },
    PayNow(){
        var pgid = Math.floor(Math.random() * (99999 - 1)) + 1;
        this.axios.post(helper.SERVICEURL + "pg/geturl", {pgid :pgid+"", username : this.Useremail,sessionid: this.sessionid }) //header
        .then(response => {
          if (response.data) {
            var tempdata = response.data
            if (tempdata.status) {
             // window.open(tempdata.purl, 'self');
              window.location.href = tempdata.purl;
            } 
          }
        });
        
    },
    submitapplication(previewmode=false){
      var validation = true
      console.log("step1")
      if(!this.digitalsign){
        if(this.$refs.signature.isEmpty()){
          $("#signatureerr").html("Applicant signature can't be empty.")
          validation=false
        } else {
          $("#signatureerr").html("")
        }
    }
    console.log("step2")
      if(this.Place==""){
        this.vmsg("Place","Place Can't be empty!")
        validation=false
      } else {
        this.vmsg("Place","")
      }

      this.Docs.forEach(x => {
        console.log("asdasdasd------>",x.docname,x.path)
                if (x.docname=="CV"){
                    if(x.path==""){
                      $("#docerror").html(x.docname+" Is mandatory. Upload a document.") 
                      console.log("asdasdasd------>",x.docname)
                      validation=false
                    } else {
                      $("#docerror").html("");
                    }
                }
              })
      
    

     
      console.log("step3")
      this.calculateprofileper()
      console.log("step4")
      if(this.profilecompleted!=100){
        alert("Can't Submit your application! Fill all mandatory fields")
      }
      
      

      if(validation) {
        var png = ""
     
        if(!this.digitalsign){
          png = this.$refs.signature.save();
        }       
        //console.log("png-->",png,png1)

        var param = {
            username : this.Useremail,
            ApplicantSign : png,
            Place:this.Place,
            digitalsign:this.digitalsign,
            IPaddress:this.IPaddress,
            Signatureby:this.Signatureby,
            GuardianName:this.GuardianName,
           
          }
          if(this.profilecompleted!=100){
            //emptryblock
          } else {
            param.SubmitedDate= this.currentdate,
            param.Createdon=this.today,
            param.ApplicationStatus ="submitted",
            param.applicationID=this.applicationID
            param.issubmitbtn = true
            param.applicatname = this.Name
            param.Appliedfor = this.selectedSubProgram
            param.PhoneNumber = this.countryCode+" "+this.Telephonem
          }
          if(this.saveapplication(param,previewmode)){
            if(this.profilecompleted==100 && !previewmode){
              this.ApplicationStatus = "submitted"
              this.pushtpcrm(param)
              
              this.currentstage = this.currentstage+1
            } 
            return false
          } else {
            this.errorFlag = true
            return this.error("Something Went Wrong!")
          }
      }

     
        
    },
    pushtpcrm(){
              const leadData = {
          "lead_name": this.familyName,
          "first_name": this.familyName,
          "Title":this.Title,
          "email": this.emailid,
          "phone": this.Telephonem+"",
          "status": "New",
          // Add other lead fields as necessary
        };

        // API call to push lead data using promises
        this.axios.post(
          'http://crm.erpenginedemo.in/api/resource/CRM Lead',
          leadData,
          {
            headers: {
              'Authorization': `token 6ce4845ef38444f:1f8c276804603ae`,
              'Content-Type': 'application/json',
              'Expect': ''
            }
          }
        )
        .then(function (response) {
          console.log('Lead Created:', response.data);
        })
        .catch(function (error) {
          console.error('Error creating lead:', error.response?.data || error.message);
        });
    },
    clear(){
      this.$refs.signature.clear();
    },
    clear2(){
      this.$refs.signature1.clear();
    },
    openPdfPreview(){
      this.ShowTable=true
      this.loadingpdf=true
      document.getElementById('pdfIframe').src=""
      var pdfUrl = helper.SERVICEURL+"filledpdf?username="+this.Useremail+"&preview=true";
      this.axios.get(pdfUrl) //header
      .then(response => {
        console.log("response",response)
        if(response.data.status){
          this.loadingpdf=false
          document.getElementById('pdfIframe').src = response.data.pdf;
        } else {
          this.loadingpdf=false
          swal({  
                  text: "Application Incomplete! Fill All Mandatory Fields!",
                  timer:2000, 
                  buttons: false,
                }); 
        }
      })
      
     
    },
    downloadFile(url){
      window.open(helper.SERVICEURL+"download?filename="+url, '_blank');
    },
    deletedocprofile(f){
      var param = f
      param.username = this.Useremail
      param.sessionid = this.sessionid
      var self = this
      this.axios.post(helper.SERVICEURL + "deletedoc", param) //header
        .then(response => {
          if (response.data) {
            var tempdata = response.data
            if (tempdata.status) {
             self.ApplicantPhoto.path=""
             self.ApplicantPhoto.docname=""
             // return this.error("Application Not saved!")
            } 
          }
        });
    },
    deletedoc(f){
      var param = f
      param.username = this.Useremail
      param.sessionid = this.sessionid
      var self = this
      this.axios.post(helper.SERVICEURL + "deletedoc", param) //header
        .then(response => {
          if (response.data) {
            var tempdata = response.data
            if (tempdata.status) {
              self.Docs = tempdata.Docs
             // return this.error("Application Not saved!")
            } 
          }
        });
    },
    handleProfilePhoto() {

      this.selectedFile = this.$refs.fileInputphoto.files[0];
      if (!['image/jpeg', 'image/png', 'image/gif'].includes(this.selectedFile.type)) {
        alert('Only JPEG, PNG, and GIF files are allowed.');
        return false;
      }
      const maxSizeMB = 2; // Maximum size allowed in megabytes
      const maxSizeBytes = maxSizeMB * 1024 * 1024;
      if (this.selectedFile.size > maxSizeBytes) {
        alert(`File size exceeds 2MB limit.`);
        return false;
      }

const formData = new FormData();
formData.append('uploadedFile', this.selectedFile);
formData.append('username', this.Useremail);
formData.append('docname', "Applicant Photo");
var self = this
  this.axios.post(helper.SERVICEURL + "upload", formData,{
headers: {
  'Content-Type': 'application/x-www-form-urlencoded',
  // Add any additional headers if needed
},
}) //header
  .then(response => {
    console.log("res-->",response)
    if (response.data) {
      var tempdata = response.data
      if (tempdata.status) {
        tempdata.Docs.forEach(x => {
                if (x.docname=="Applicant Photo"){
                  self.ApplicantPhoto.docname = x.docname
                  self.ApplicantPhoto.path=x.path
                }
              })
       // return this.error("Application Not saved!")
      } 
    }
  });
},
    handleFileChange() {
      this.selectedFile = this.$refs.fileInput.files[0];

      if (![
            'image/jpeg', 
            'image/png', 
            'image/gif', 
            'application/pdf',          // PDF files
            'application/msword',       // DOC files (Microsoft Word)
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // DOCX files
            'application/vnd.ms-excel', // XLS files (Microsoft Excel)
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // XLSX files
            'application/vnd.ms-powerpoint', // PPT files (Microsoft PowerPoint)
            'application/vnd.openxmlformats-officedocument.presentationml.presentation', // PPTX files
            'text/plain'                // Plain text files (TXT)
          ].includes(this.selectedFile.type)) {
            alert('Only JPEG, PNG, GIF, PDF, DOC, DOCX, XLS, XLSX, PPT, PPTX, and TXT files are allowed.');
            return false;
          }

      const maxSizeMB = 5; // Maximum size allowed in megabytes
      const maxSizeBytes = maxSizeMB * 1024 * 1024;
      if (this.selectedFile.size > maxSizeBytes) {
        alert(`File size exceeds 5MB limit.`);
        return false;
      }

      const formData = new FormData();
      formData.append('uploadedFile', this.selectedFile);
      formData.append('username', this.Useremail);
      formData.append('docname', this.docname);
      var self = this
        this.axios.post(helper.SERVICEURL + "upload", formData,{
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        // Add any additional headers if needed
      },
    }) //header
        .then(response => {
          console.log("res-->",response)
          if (response.data) {
            var tempdata = response.data
            if (tempdata.status) {
              //self.Docs = tempdata.Docs
              self.Docs.forEach((element, index) => {
                tempdata.Docs.forEach(x => {
                          if (x.docname==element.docname){
                            self.Docs[index].path = x.path
                          }
                        });
                });
             // return this.error("Application Not saved!")
            } 
          }
        });
    },
    addmoreCurricular(){
      var validated = true
      if (this.SportsGames==""){
          this.vmsg("SportsGames","Sports/Games can't be empty.")
          validated=false
        }  else {
          this.vmsg("SportsGames","")
        }

        if (this.National==""){
          this.vmsg("National","National can't be empty.")
          validated=false
        }  else {
          this.vmsg("National","")
        }

        if (this.ECState==""){
          this.vmsg("ECState","State can't be empty.")
          validated=false
        }  else {
          this.vmsg("ECState","")
        }

        if (this.Devision==""){
          this.vmsg("Devision","Devision can't be empty.")
          validated=false
        }  else {
          this.vmsg("Devision","")
        }

        if (this.ECDistrict==""){
          this.vmsg("ECDistrict","District can't be empty.")
          validated=false
        }  else {
          this.vmsg("ECDistrict","")
        }

        if(this.ExtraCurricular.length>=3){
          alert("Can't Enter More than 3 Extra Curricular")
          validated=false
        }

        if (validated) {
          var jobj = {
            SportsGames:this.SportsGames,
            National:this.National,
            ECState:this.ECState,
            Devision:this.Devision,
            ECDistrict:this.ECDistrict,
          }

          this.ExtraCurricular.push(jobj)
          this.resetExtraCurricular()

        }
    },
    removeqa(i){
      this.Qualifying.splice(i, 1);
    },
    removeExtraCurricular(i){
      this.ExtraCurricular.splice(i, 1);
    },
    resetExtraCurricular(){
      this.SportsGames=""
      this.National=""
      this.ECState=""
      this.Devision=""
      this.ECDistrict=""
    },
    qavalidate(){
      if(this.nextclicked2==false){
        return false
      }
      var validated = true
      
      //var currentyear = today.getFullYear() 
      const attenddate = new Date(this.attenddate);
      //const Completiondate = new Date(this.Completiondate);
      const todays = new Date(); // Gets the current date and time

      // Set the time part to zero if you're only comparing dates
      attenddate.setHours(0, 0, 0, 0);
      todays.setHours(0, 0, 0, 0);

      if (this.attenddate==""){
          this.vmsg("attenddate","Attendance End Date can't be empty.")
          validated=false
        }
        //  else  if (attenddate>todays){
        //   this.vmsg("attenddate","Attendance End Date can't be more than current Date.")
        //   validated=false
        // }
         else {
          this.vmsg("attenddate","")
        }
        //const attstartdate = new Date(this.attstartdate);
        if (this.attstartdate==""){
          this.vmsg("attstartdate","Attendance Start Date can't be empty.")
          validated=false
        } 
        // else  if (attstartdate>todays){
        //   this.vmsg("attstartdate","Attendance Start Date can't be more than current Date.")
        //   validated=false
        // } else  if (attstartdate>attenddate){
        //   this.vmsg("attstartdate","Attendance Start Date can't be more than End Date.")
        //   validated=false
        // } 
        else {
          this.vmsg("attstartdate","")
        }



        if (this.InstitutionAddress==""){
          this.vmsg("InstitutionAddress","Institution Address can't be empty.")
          validated=false
        } else {
          this.vmsg("InstitutionAddress","")
        }

        if (this.Degree==""){
          this.vmsg("Degree","Degree can't be empty.")
          validated=false
        } else {
          this.vmsg("Degree","")
        }
        

        if (this.Completiondate==""){
          this.vmsg("Completiondate","Completion Date can't be empty.")
          validated=false
        }
        //  else  if (Completiondate>todays){
        //   this.vmsg("Completiondate","Completion Date can't be more than current Date.")
        //   validated=false
        // } else  if (Completiondate<attenddate){
        //   this.vmsg("Completiondate","Completion Date can't be less than End Date.")
        //   validated=false
        // } 
        else {
          this.vmsg("Completiondate","")
        }
    
        if (this.Institution==""){
          this.vmsg("Institutionfield","Institution can't be empty.")
          validated=false
        } else {
          this.vmsg("Institutionfield","")
        }

      
        if (this.Qualifying.some(obj => obj["Institution"] === this.Institution)) {
          this.vmsg("Institution","Institution Already added!")
          validated=false
        } else {
          this.vmsg("Institution","")
        }
        return validated
    },
    addmoreQualifying(){
      
        this.nextclicked2 = true
        if (this.qavalidate()) {
          var jobj = {
            Completiondate : this.Completiondate,
            Institution :this.Institution,
            InstitutionAddress:this.InstitutionAddress,
            attstartdate:this.attstartdate,
            attenddate:this.attenddate,
            Degree:this.Degree,
          }

          this.Qualifying.push(jobj)
          this.resetqa()

        }

    },
    resetqa(){
      this.Completiondate=""
      this.Institution=""
      this.InstitutionAddress=""
      this.attstartdate=""
      this.attenddate = ""
      this.Degree=""     
    },
    validate4(){
      var validated = true

      if ((this.convicted=="Yes" || this.ninethgrad =="Yes") &&  (this.incidentdetails=="")) {
          this.vmsg("incidentdetails","Please provide an explanation and the approximate dates!")
          validated=false
        } else {
          this.vmsg("incidentdetails","")
        }

        return validated
    },
    validate1(){
      if(!this.nextclicked1){
        return false
      }
        var validated = true
        if (this.Name==""){
          this.vmsg("Name","Name can't be empty.")
          validated=false
        } else if (this.Name.length>24) {
          this.vmsg("Name","Name can't be more than 24 character.")
          validated=false
        } else {
          this.vmsg("Name","")
        }
        
        if (this.ApplicantPhoto.path==""){
          this.vmsg("applicatphoto","Applicant Photo can't be empty.")
          validated=false
        } else {
          this.vmsg("applicatphoto","")
        }
        if (this.Gender==""){
          this.vmsg("Gender","Gender can't be empty.")
          validated=false
        } else {
          this.vmsg("Gender","")
        }

        if (this.DOB==""){
          this.vmsg("DatepickerDOB","DOB can't be empty.")
          validated=false
        } else {
          this.vmsg("DatepickerDOB","")
        }

        if (this.Title==""){
          this.vmsg("Title","Title can't be empty.")
          validated=false
        } else {
          this.vmsg("Title","")
        }
        
  
        if (this.familyName==""){
          this.vmsg("familyName","Surname/Family can't be empty.")
          validated=false
        } else {
          this.vmsg("familyName","")
        }
        if (this.Citizenship==""){
          this.vmsg("Citizenship","Citizenship can't be empty.")
          validated=false
        } else {
          this.vmsg("Citizenship","")
        }
        const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        if (!pattern.test(this.emailid)) {
          this.vmsg("emailid", "Enter a valid email ID.");
        } else if (this.emailid==""){
          this.vmsg("emailid","Email id can't be empty.")
          validated=false
        } else {
          this.vmsg("emailid","")
        }
        
        if (this.EmergencyName==""){
          this.vmsg("EmergencyName","Emergency Contact Name can't be empty.")
          validated=false
        } else {
          this.vmsg("EmergencyName","")
        }

        if (this.ECRelationship==""){
          this.vmsg("ECRelationship","Relationship can't be empty.")
          validated=false
        } else {
          this.vmsg("ECRelationship","")
        }

        if (this.PermanentAddress==""){
          this.vmsg("PermanentAddress","Permanent Address can't be empty.")
          validated=false
        } else {
          this.vmsg("PermanentAddress","")
        }

        if (this.MailingAddress==""){
          this.vmsg("MailingAddress","Mailing Address can't be empty.")
          validated=false
        } else {
          this.vmsg("MailingAddress","")
        }

        if (this.EmergencyTelephone==""){
          this.vmsg("EmergencyTelephone","Emergency Telephone can't be empty.")
          validated=false
        } else {
          this.vmsg("EmergencyTelephone","")
        }

        if (!pattern.test(this.EmergencyEmail)) {
          this.vmsg("EmergencyEmail", "Enter a valid Emergency email ID.");
        } else if (this.EmergencyEmail==""){
          this.vmsg("EmergencyEmail","Emergency Email can't be empty.")
          validated=false
        } else {
          this.vmsg("EmergencyEmail","")
        }
        
        if (this.countryCode==""){
          this.vmsg("countryCode","Country Code can't be empty.")
          validated=false
        } else {
          this.vmsg("countryCode","")
        }

        if (this.Telephonem==""){
          this.vmsg("Telephonem","Telephone Mobile can't be empty.")
          validated=false
        } else {
          this.vmsg("Telephonem","")
        }

        return validated
    },
    validate3(){
      if(this.nextclicked3==false){
        return false
      }
      var validated = true
      if (this.selectedSubProgram==""){
        $("#selectedP").html("Select any one from the Program List Below.")
          validated=false
        } else {
          $("#selectedP").html("")
        }
      if (this.Degreeyear==""){
          this.vmsg("Degreeyear","Year can't be empty.")
          validated=false
        } else {
          this.vmsg("Degreeyear","")
        }

        if (this.Semester==""){
          this.vmsg("Semester","Semester can't be empty.")
          validated=false
        } else {
          this.vmsg("Semester","")
        }
        return validated
    },
    calculateAge(birthdate) {
  // Parse the birthdate string into a Date object
  const birthDateObj = birthdate;

  // Get the current date
  const currentDate = new Date();
      console.log("birthDateObj-->",birthDateObj)
  // Calculate the difference in years
  const age = currentDate.getFullYear() - birthDateObj.getFullYear();

  // Adjust the age if the birthday hasn't occurred yet this year
  if (
    currentDate.getMonth() < birthDateObj.getMonth() ||
    (currentDate.getMonth() === birthDateObj.getMonth() &&
      currentDate.getDate() < birthDateObj.getDate())
  ) {
    return age - 1;
  }

  return age;
},
calculateprofileper(){
  var filled = 0
  var requiredfields = ["Name","Gender","DOB","Title","familyName","Citizenship","Telephonem","emailid","EmergencyName","ECRelationship","PermanentAddress","MailingAddress","EmergencyTelephone","EmergencyEmail","selectedSubProgram","Semester","Degreeyear","Place","Qualifying","digitalsign","ApplicantPhoto","countryCode"]
        if (this.digitalsign){
          let index = requiredfields.indexOf("ApplicantSign");
         
           if (index !== -1) {
                    requiredfields.splice(index, 1);
          }
        }
        for (let index = 0; index < requiredfields.length; index++) {
          console.log(requiredfields[index],this.$data[requiredfields[index]])
          if(this.$data[requiredfields[index]].length!=0 && this.$data[requiredfields[index]]!=undefined){
           // console.log(requiredfields[index],this.$data[requiredfields[index]])
            filled=filled+1
            //console.log("--->",requiredfields[index])
          } else {
            console.log("non filled-->",requiredfields[index])
          }
        }

        if (this.Qualifying>0){
          filled=filled+1
        }
       var tol = requiredfields.length
       const percentage = (filled / tol) * 100;
       console.log("filled-->",filled,tol)
       if (percentage>0){
        this.profilecompleted = parseInt(percentage)
       }

        
},
saveapplication(param,previewmode=false){
      var self = this
      var status = true
      param.SessionId = self.sessionid
      if(previewmode){
          param.issubmitbtn = false
      }
      this.axios.post(helper.SERVICEURL + "applications/create", param) //header
        .then(response => {
          if (response.data) {
            var tempdata = response.data
            if (tempdata.status) {
              self.calculateprofileper()
              if(previewmode){
                self.openPdfPreview()
              }
             // return this.error("Application Not saved!")
            } 
          }
        });
        return status
},
back(){
  if (this.currentstage!=1){
    this.currentstage = this.currentstage -1
  }
},
    next(){
      if (this.currentstage==1){
        this.nextclicked1 = true
        if(!this.validate1()){
          return false
        } else {
          var param = {
            username : this.Useremail,
            Name: this.Name.toUpperCase(),
            DOB:this.DOB,
            Gender:this.Gender,
            Title:this.Title,
familyName:this.familyName,
OldName:this.OldName,
Citizenship:this.Citizenship,
emailid:this.emailid,
Telephoneh:this.Telephoneh,
Telephonem:this.Telephonem,
countryCode:this.countryCode,
EmergencyName:this.EmergencyName,
ECRelationship:this.ECRelationship,
PermanentAddress:this.PermanentAddress,
MailingAddress:this.MailingAddress,
HomeAddress:this.HomeAddress,
EmergencyTelephone:this.EmergencyTelephone,
EmergencyEmail:this.EmergencyEmail,
fathernameminor:this.fathernameminor,
emailidminor:this.emailidminor,
telephoneminor:this.telephoneminor,
mothernameminor:this.mothernameminor,
Motheremailminor:this.Motheremailminor,
telephonemotherminor:this.telephonemotherminor
          }
          if(this.saveapplication(param)){
            this.currentstage = this.currentstage+1
            return false
          } else {
            this.errorFlag = true
            return this.error("Name is required!")
          }
        }
      }
      //stage 2
      if (this.currentstage==2){
        if(this.Qualifying.length==0){
          alert("Add Any Educational Background.")
          return false
        } else {
            param = {
            username : this.Useremail,
            Qualifying : this.Qualifying
          }
          if(this.saveapplication(param)){
            this.currentstage = this.currentstage+1
            return false
          } else {
            this.errorFlag = true
            return this.error("Something Went Wrong!")
          }
        }
      }
      //stage 3
      if (this.currentstage==3){
        this.nextclicked3=true
        if(!this.validate3()){
          return false
        } else {
            param = {
            username : this.Useremail,
            Degreeyear:this.Degreeyear,
            Semester:this.Semester,
            ClinicalProgram:this.ClinicalProgram,
            ScienceProgram:this.ScienceProgram,
            PremedicalProgram:this.PremedicalProgram,
            PGProgram:this.PGProgram,
            MDProgram:this.MDProgram,
            selectedProgram:this.selectedProgram,
            selectedSubProgram:this.selectedSubProgram,
          }


          if(this.saveapplication(param)){
            this.currentstage = this.currentstage+1
            return false
          } else {
            this.errorFlag = true
            return this.error("Something Went Wrong!")
          }
        }
      }
       //stage 4
       if (this.currentstage==4){
        if(!this.validate4()){
          return false
        } else {
            param = {
            username : this.Useremail,
            trInstitution1 : this.trInstitution1,
            ccInstitution1 : this.ccInstitution1,

            trInstitution2 : this.trInstitution2,
            ccInstitution2 : this.ccInstitution2,

            trInstitution3 : this.trInstitution3,
            ccInstitution3 : this.ccInstitution3,

            trInstitution4 : this.trInstitution4,
            ccInstitution4 : this.ccInstitution4,
            ninethgrad : this.ninethgrad,
            convicted  : this.convicted,
            incidentdetails : this.incidentdetails,
            howyouknow:this.howyouknow,
          }

       
          if(this.saveapplication(param)){
            this.currentstage = this.currentstage+1
            return false
          } else {
            this.errorFlag = true
            return this.error("Something Went Wrong!")
          }
        }
      }

    },
    vmsg(id,text){
      if (text==""){
        $('#'+id).next("p.errtext").remove();
        $('#'+id).removeClass('error-border');
      } else {
        $('#'+id).next("p.errtext").remove();
        $('#'+id).after('<p class="errtext">'+text+'</p>')
        $('#'+id).addClass('error-border');
      }
    },
    loginvalidate(){
      let valudated = true
        if(this.loginbtnclicked){
            if(this.iscreateaccount){
              if (this.UserPassword.length<8) {
                this.vmsg("UserPassword", "Password should have minimum 8 characters.");
                valudated = false
              } else {
                this.vmsg("UserPassword", "");
              }

              if (this.UserPassword!=this.UserConfirmPassword) {
                this.vmsg("UserConfirmPassword", "Confirm Password Not Matched!");
                valudated = false
              } else {
                this.vmsg("UserConfirmPassword", "");
              }
            }


            const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

            if (!pattern.test(this.Useremail)) {
              this.vmsg("email", "Enter a valid email ID.");
              valudated = false
            } else {
              this.vmsg("email", "");
            }

            // if(this.captchaToken==""){
            //   swal({  
            //       text: "Confirm the Captcha to proceed further",
            //       timer:2000, 
            //       buttons: false,
            //     }); 
            //     valudated = false
            // } 

        }

        return valudated
    },
    logincheck(){
      this.loginbtnclicked = true
      
      if(this.loginvalidate()){
        const article = { "Useremail": this.Useremail };
      var self = this
      if (this.SkipOTP){
              self.sessionid = this.adminsessionid
              self.login=false
              self.OTPpage=false
              self.formpage=true
              self.currentstage=1
              self.loaduser()
      } else {
        let apurl = "user/sendotp"
        if(this.iscreateaccount){
          apurl = "user/cusersendotp"
        }
      this.axios.post(helper.SERVICEURL + apurl, article) //header
        .then(response => {
          if (response.data) {
            var tempdata = response.data
            if (tempdata.status) {
              self.login=false
              self.OTPpage=true
            } else {
              self.vmsg("email",tempdata.Msg)
            }
          }
        });
      }
    }
      
    },
    otpcheck(){

      const pattern = /^\d{4}$/;
      //var temptext =        this.mobilenumber+""
      if(!pattern.test(this.otp)){
        this.vmsg("otp","OTP number has to be 4 digits long.")
      } else {
        this.vmsg("otp","")
        const article = { "otp": this.otp , "username":this.Useremail};
      var self = this
      this.axios.post(helper.SERVICEURL + "user/validateotp", article) //header
        .then(response => {
          if (response.data) {
            var tempdata = response.data
            if (tempdata.status) {
              if(self.iscreateaccount==true){
                const article = {
                  "username":self.Useremail,
                  "firstname":"",
                  "lastname":"",
                  "IsActive":true,
                  "password":this.UserPassword,
                  "usergroup":"Student",
                  };
                  var Actionurl= "user/create"
                  this.axios.post(helper.SERVICEURL+Actionurl, article)
          .then(response => {
            if(response.data.status){
                swal({  
                  text: response.data.Msg,
                  timer:2000, 
                  buttons: false,
                }); 

                self.sessionid = tempdata.sessionid
                console.log("comingotpvalidate---",tempdata)
                self.login=false
                self.OTPpage=false
                self.formpage=true
                self.currentstage=1
                self.loaduser()
        
                 
            }  else {
              swal({  
                  text: response.data.Msg,
                  timer:2000, 
                  buttons: false,
                }); 
            }     
          });
              } else {
                self.sessionid = tempdata.sessionid
                console.log("comingotpvalidate---",tempdata)
                self.login=false
                self.OTPpage=false
                self.formpage=true
                self.currentstage=1
                self.loaduser()
              }
              
            } else {
              self.vmsg("otp",tempdata.Msg)
            }
          }
        });
        
      }
    },
    isemailexsit() {
      const article = { "MailId": this.MailId };
      var self = this
      this.axios.post(helper.SERVICEURL + "applications/isemailexists", article) //header
        .then(response => {
          if (response.data) {
            var tempdata = response.data
            if (tempdata.Isemailexists) {
              self.Isemailexists = true
              this.errorFlag = true
              return this.error("Email id already exists!")
            } else {
              self.Isemailexists = false
            }
          }
        });
    },
    setdob(e) {
      this.DOB = moment(e, 'DD-MM-YYYY')
    },
    filterChange(event) {
      this.WorkLocationList = this.filterData(event.filter);
    },
    filterData(filter) {
      const data = this.MainWorkLocationList;
      return filterBy(data, filter);
    },
    PreffilterChange(event) {
      this.PreferredLocationList = this.PreffilterData(event.filter);
    },
    PreffilterData(filter) {
      const data = this.MainWorkLocationList;
      return filterBy(data, filter);
    },
    JobfilterChange(event) {
      this.JobCategoryList = this.JobfilterData(event.filter);
    },
    JobfilterData(filter) {
      const data = this.JobCategoryListMain;
      return filterBy(data, filter);
    },
    QualfilterChange(event) {
      this.QualificationList = this.QualfilterData(event.filter);
    },
    QualfilterData(filter) {
      const data = this.QualificationListMain;
      return filterBy(data, filter);
    },
    JobCategoryChange(event) {
      if (event.value == "Other") {
        this.IsJobCategory = true
        this.JobCategory = ""
        this.Designation = ""
      } else {
        this.IsJobCategory = false
        //his.value = event.value;
        var self = this
        self.DesignationList = []
        self.Designation = ""
        const article = { "jobcategory.jobtitle": event.value };
        //  const header = {header : {SessionId: "113"}}
        this.axios.post(helper.SERVICEURL + "designation/get", article) //header
          .then(response => {
            if (response.data) {
              var tempdata = response.data
              tempdata.forEach(x => {
                let temp = x.designation
                self.DesignationList.push(temp)
              })
            }
          });
      }
    },
    onVerify(response) {
      console.log("response--->", response)
      if (response) {
        this.imnotroboot = true;
      }
    },
    validate() {
      this.errorFlag = false
      return new Promise(resolve => {
        // //eslint-disable-next-line
        const regexp = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
        if (this.Name == "") {
          this.errorFlag = true
          return this.error("Name is required!")
        }
        if (this.MailId == "") {
          this.errorFlag = true
          return this.error("Email is required!")
        } else if (this.Isemailexists) {
          this.errorFlag = true
          return this.error("Email id already exists!!")
        } else if (!this.MailId.match(regexp)) {
          this.errorFlag = true
          return this.error("Email is not valid!")
        }
        if (this.Gender == "") {
          this.errorFlag = true
          return this.error("Gender is required!")
        }
        if (this.PhoneNumber == "") {
          return this.error("Phone number is required!")
        } else if (this.PhoneNumber.length != 10) {
          return this.error("Phone number is not valid!")
        }
        if (this.WorkLocation == "") {
          this.errorFlag = true
          return this.error("WorkLocation is required!")
        }
        if (this.Qualification == "") {
          this.errorFlag = true
          return this.error("Qualification is required!")
        }
        if (this.JobCategory == "") {
          this.errorFlag = true
          return this.error("Job Category is required!")
        }
        if (this.Designation == "") {
          this.errorFlag = true
          return this.error("Designation is required!")
        }
        if (this.Experience == "") {
          this.errorFlag = true
          return this.error("Experience is required!")
        }
        if (this.DOB == "") {
          this.errorFlag = true
          return this.error("Date of Birth is required!")
        }
        if (this.PreferredLocation == "") {
          this.errorFlag = true
          return this.error("Preferred Location is required!")
        }
        if (this.TechnicalSkillsComma == "") {
          this.errorFlag = true
          return this.error("Technical Skills is required!")
        }
        if (this.JobAppliedfor == "") {
          this.errorFlag = true
          return this.error("Job Applied for is required!")
        }

        if (this.CtcRange == "") {
          this.errorFlag = true
          return this.error("CTC Range is required!")
        }

        if (this.Experience != "Fresher") {
          this.EmploymentHistory.forEach(x => {
            if (x.PreOrganizationName == undefined || x.PreOrganizationName == "" ||
              x.StartDate == undefined || x.StartDate == "" ||
              x.EndDate == undefined || x.EndDate == "" ||
              x.PreDesignation == undefined || x.PreDesignation == "") {
              this.errorFlag = true
              this.failFlag = true
              return this.error("All Fields in Employment History is Required !")
            }
            if (this.failFlag) {
              return false
            }
          })
        }
        if (!this.imnotroboot) {
          this.errorFlag = true
          return this.error("Verify that you are not a Robot!")
        }
        setTimeout(() => {
          console.log("this.errorFlag", this.errorFlag)
          if (!this.errorFlag) {
            resolve(true)
          } else {
            resolve(false)
          }
        }, "500")
      });
    },
    error(text) {
      var _this = this
      if (this.maxalert) {
        this.maxalert = false
      }
      this.max = text
      this.maxalert = true
      setTimeout(function () {
        _this.maxalert = false;
      }, 3000);
      return false;
    },
    isNumber(evt) {
      const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.'];
      const keyPressed = evt.key;

      if (!keysAllowed.includes(keyPressed)) {
        evt.preventDefault()
      }
    },
    setData(d){
      var self = this
      this.ApplicationStatus = d.ApplicationStatus || ""
      this.Gender = d.Gender
      this.Age = d.Age
      this.AnnualIncome = d.AnnualIncome
      this.Caste = d.Caste
      this.CommunicationAddress = d.CommunicationAddress
      this.Community = d.Community
      this.DOB = d.DOB
      this.Title = d.Title
      this.familyName = d.familyName
      this.Name = d.Name,
      this.OldName = d.OldName
      this.Citizenship = d.Citizenship
      this.emailid = d.emailid
      this.Telephoneh  = d.Telephoneh
      this.Telephonem = d.Telephonem
      this.countryCode= d.countryCode|| ""
      this.EmergencyName = d.EmergencyName
      this.ECRelationship = d.ECRelationship
      this.PermanentAddress = d.PermanentAddress
      this.MailingAddress = d.MailingAddress
      this.HomeAddress = d.HomeAddress
      this.EmergencyTelephone = d.EmergencyTelephone
      this.EmergencyEmail = d.EmergencyEmail
      this.fathernameminor = d.fathernameminor
      this.emailidminor = d.emailidminor
      this.telephoneminor = d.telephoneminor
      this.mothernameminor = d.mothernameminor
      this.Motheremailminor = d.Motheremailminor
      this.telephonemotherminor = d.telephonemotherminor

      this.isotherReligion = d.isotherReligion
      this.Qualifying = d.Qualifying || []
      this.digitalsign = d.digitalsign || false
      

      this.Degreeyear=d.Degreeyear || "",
      this.Semester=d.Semester || "",
      this.ClinicalProgram=d.ClinicalProgram,
      this.ScienceProgram=d.ScienceProgram,
      this.PremedicalProgram=d.PremedicalProgram,
      this.PGProgram=d.PGProgram,
      this.MDProgram=d.MDProgram,
      this.selectedProgram = d.selectedProgram || "",
      this.selectedSubProgram = d.selectedSubProgram || "",

      this.trInstitution1 = d.trInstitution1 || "",
      this.ccInstitution1 = d.ccInstitution1 || "",

      this.trInstitution2 = d.trInstitution2 || "",
      this.ccInstitution2 = d.ccInstitution2 || "",

      this.trInstitution3 = d.trInstitution3 || "",
      this.ccInstitution3 = d.ccInstitution3 || "",

      this.trInstitution4 = d.trInstitution4 || "",
      this.ccInstitution4 = d.ccInstitution4 || "",
      this.ninethgrad = d.ninethgrad || "No",
      this.convicted  = d.convicted || "No",
      this.incidentdetails = d.incidentdetails  || "",
      this.howyouknow=d.howyouknow  || "",

      this.ExtraCurricular =d.ExtraCurricular || []
      this.PhysicalIdentification1=d.PhysicalIdentification1
      this.PhysicalIdentification2=d.PhysicalIdentification2
      this.Transport=d.Transport
      this.Route=d.Route
      this.Stage=d.Stage
      this.BoardingPoint=d.BoardingPoint
      this.Hostel=d.Hostel
      this.BloodGroup=d.BloodGroup
      this.exserviceman=d.exserviceman
      this.exservicemanNo=d.exservicemanNo
      this.exservicemanRank=d.exservicemanRank
      this.firstgraduate=d.firstgraduate
      this.singleChild=d.singleChild
      this.differentlyabled=d.differentlyabled
      this.NSSYRC=d.NSSYRC

     // this.Docs =d.Docs || []
     this.Docs.forEach((element, index) => {
      d.Docs.forEach(x => {
                if (x.docname==element.docname){
                  self.Docs[index].path = x.path
                }
              });
      });

      d.Docs.forEach(x => {
                if (x.docname=="Applicant Photo"){
                  this.ApplicantPhoto.docname = x.docname
                  this.ApplicantPhoto.path=x.path
                }
              })

      this.Place = d.Place || ""
      this.Signatureby = d.Signatureby || ""
      this.GuardianName = d.GuardianName || ""
      this.Branch = d.Branch
      this.admittingyear = d.admittingyear
      this.ApplicantSign = d.ApplicantSign || ""
      this.ParentSign = d.ParentSign
      this.applicationID = d.applicationID

      this.RollNo= d.RollNo
      this.UniversityRegnNo= d.UniversityRegnNo
      this.Section= d.Section
      this.TotalDocuments= d.TotalDocuments || 0
      this.mandatorydetailsverified = d.mandatorydetailsverified || ""
      this.mandatorytranscriptsverified = d.mandatorytranscriptsverified || ""
      this.ApplicantPhotoverified = d.ApplicantPhotoverified || ""
      this.Othersdoc= d.Othersdoc || false
      this.SCorSTBankAccNodoc= d.SCorSTBankAccNodoc || false
      this.ConductCertificatedoc= d.ConductCertificatedoc || false
      this.CommunityCertificatedoc= d.CommunityCertificatedoc || false
      this.SCorSTIncomeCertificatedoc= d.SCorSTIncomeCertificatedoc || false
      this.SCorSTAttendanceCertificatedoc= d.SCorSTAttendanceCertificatedoc || false
      this.TransferCertificatedoc= d.TransferCertificatedoc || false
      this.sslcdoc= d.sslcdoc || false
    },
    loaduser() {
      //this.Mode = "NEW"
      const article = {"username":this.Useremail};
      //  const header = {header : {SessionId: "113"}}
      this.axios.post(helper.SERVICEURL + "applications/getbyid", article) //header
        .then(response => {
          if (response.data.length>0) {
            console.log("userdata-->",response.data)
            this.setData(response.data[0])
            this.calculateprofileper()
          } else {
            this.emailid = this.Useremail
          }
        });

    },

    DOBhandleChange(event) {
      this.DOB = event.target.value;
    },
    branchList(){
      var self = this;
      const article = {
        "IsActive":true
      };
      this.axios.post(helper.SERVICEURL + "branchtitle/get", article)
        .then(response => {
          if (response.data) {
            var tempdata = response.data
            tempdata.forEach(x => {
              let temp = x.branchtitle
              self.Branchmaster.push(temp)
            })
          }
        });
    },
    genderList() {
      var self = this;
      const article = {};
      this.axios.post(helper.SERVICEURL + "gender/get", article)
        .then(response => {
          if (response.data) {
            var tempdata = response.data
            tempdata.forEach(x => {
              let temp = x.gender
              self.GenderList.push(temp)
            })
          }
        });
    },

    qualificationList() {
      var self = this;
      const article = {};
      this.axios.post(helper.SERVICEURL + "qualification/get", article)
        .then(response => {
          if (response.data) {
            var tempdata = response.data
            tempdata.forEach(x => {
              let temp = x.qualification
              self.QualificationListMain.push(temp)
              self.QualificationList.push(temp)
            })
          }
        });
    },

    experienceList() {
      var self = this;
      const article = {};
      this.axios.post(helper.SERVICEURL + "experience/get", article)
        .then(response => {
          if (response.data) {
            var tempdata = response.data
            tempdata.forEach(x => {
              let temp = x.experience
              self.ExperienceList.push(temp)
            })
          }
        });
    },

    workLocationList() {
      var self = this;
      const article = {};
      this.axios.post(helper.SERVICEURL + "district/get", article)
        .then(response => {
          if (response.data) {
            var tempdata = response.data
            tempdata.forEach(x => {
              let temp = x.district
              self.MainWorkLocationList.push(temp)
              self.WorkLocationList.push(temp)
              self.PreferredLocationList.push(temp)
            })
          }
        });
    },

    designationList() {
      console.log("@@@@@designationList")
      var self = this;
      const article = {};
      this.axios.post(helper.SERVICEURL + "jobtitle/get", article)
        .then(response => {
          if (response.data) {
            var tempdata = response.data
            tempdata.forEach(x => {
              let temp = x.jobtitle
              self.JobCategoryList.push(temp)
              self.JobCategoryListMain.push(temp)
            })
          }
        });
    },

    handlefile(e) {
      const selectedfile = e.target.files[0]; // get first file
      this.createBase64Image(selectedfile);
    },
    createBase64Image(fileObject) {
      const reader = new FileReader();
      reader.onload = (e) => {
        var fileBase64 = e.target.result;
        this.FileName = fileObject.name;
        this.file = fileBase64.split(",")[1]
      };
      reader.readAsDataURL(fileObject);
    },

    async save() {

      const result = await this.validate();
      console.log("result", result)
      if (!result) {
        return
      }


      var temparr = []
      if (this.TechnicalSkills.length > 0) {
        this.TechnicalSkills.forEach(x => {
          var tempstr = ""
          tempstr = x.replace(/^\s+|\s+$/gm, '')
          temparr.push(tempstr)
        })
      }
      const article = {
        "Gender": this.Gender,
        "Name": this.Name,
        "MailId": this.MailId,
        "PhoneNumber": this.PhoneNumber,
        "WorkLocation": this.WorkLocation,
        "PreferredLocation": this.PreferredLocation,
        "Qualification": this.Qualification,
        "Designation": this.Designation,
        "JobCategory": this.JobCategory,
        "Experience": this.Experience,
        "JobAppliedfor": this.JobAppliedfor,
        "Filebase": this.file,
        "filename": this.FileName,
        "DOB": this.DOB,
        "TechnicalSkills": temparr,
        "Candidatecommands": this.Candidatecommands,
        "EmploymentHistory": this.EmploymentHistory,
        "CtcRange": this.CtcRange,
        "IsWorklocationOthers": this.IsWorklocationOthers,
        "IsCtcRange": this.IsCtcRange,
        "IsQualification": this.IsQualification,
        "IsExperience": this.IsExperience,
        "IsJobCategory": this.IsJobCategory,
      };
      var Actionurl = "applications/create"
      this.PageLoading = true
      this.axios.post(helper.SERVICEURL + Actionurl, article)
        .then(response => {
          this.PageLoading = false
          if (response.data.status) {
            swal({
              buttons: false,
              text: response.data.Msg,
              timer: 2000,
            });
            this.CloseFlag = true
          } else {
            swal({
              text: response.data.Msg,
              timer: 2000,
              buttons: false,
            });
          }
        });

    },
    RemoveRange(i) {
      var self = this;
      if (self.EmploymentHistory.length == 0) {
        return;
      }
      if (i == undefined) {
        i = self.EmploymentHistory.length - 1;
      }
      self.EmploymentHistory.splice(i, 1);
    },
    AddRange() {
      var condcheck = true
      // this.EmploymentHistory.forEach((v) => {
      // })
      if (condcheck) {
        if (this.EmploymentHistory.length == 0) {
          var tmp = {

          };
          this.EmploymentHistory.push(tmp);
        } else {
          tmp = {

          };
          this.EmploymentHistory.push(tmp);
        }
      }
    },
    ctcchange(e) {
      if (e.value == "Other") {
        this.CtcRange = ""
        this.IsCtcRange = true
      } else {
        this.IsCtcRange = false
      }
    },
    workLocchange(e) {
      if (e.value == "Other") {
        this.IsWorklocationOthers = true
        this.WorkLocation = ""
      } else {
        this.IsWorklocationOthers = false
      }
    },
    QualChange(e) {
      if (e.value == "Other") {
        this.IsQualification = true
        this.Qualification = ""
      } else {
        this.IsQualification = false
      }
    },
    ExepChange(e) {
      if (e.value == "Other") {
        this.IsExperience = true
        this.Experience = ""
      } else {
        this.IsExperience = false
      }
    }
  },
};
</script>

<style>
.mycheckbx{
  float: left;
    width: 30px !important;
    margin-right: 10px;
}
#maxalertclient {
  position: fixed;
  padding: 20px;
  z-index: 10000;
  color: #fff;
  background: #bf0909;
  bottom: 200px;
  right: 10px;
  border-radius: 10px;
}


.mybg{
  position: fixed;
  width: 100%;
  height: 100%;
  background: url(../assets/img/webister-01.png);
  background-size: cover;
  z-index: -100;
}


@font-face {
  font-family: poppins-regular;
  src: url(../assets/fonts/poppins/Poppins-Regular.ttf)
}

@font-face {
  font-family: poppins-semibold;
  src: url(../assets/fonts/poppins/Poppins-SemiBold.ttf)
}

@font-face {
  font-family: poppins-medium;
  src: url(../assets/fonts/poppins/Poppins-Medium.ttf)
}

@font-face {
  font-family: muli-bold;
  src: url(../assets/fonts/muli/Muli-Bold.ttf)
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box
}

:focus {
  outline: none
}


ul {
  padding: 0;
  margin: 0;
  list-style: none
}

a {
  text-decoration: none
}

textarea {
  resize: none
}

select {
  cursor: pointer;
  border: none
}

select option[value=""][disabled] {
  display: none
}

img {
  max-width: 100%;
  vertical-align: middle
}

.wrapperat {
  max-width: 1400px;
  height: 100vh;
  margin: auto;
  display: flex;
  align-items: center
}

.wrapperat .image-holder {
  width: 41%
}

.wrapperat form {
  width: 49%
}

.wizard>.steps .current-info,
.wizard>.steps .number {
  display: none
}

#wizard {
  min-height: 834px;
  background: #fff;

  padding: 107px 75px 65px
}

.steps {
  height: 30px;
}

.steps ul {
  display: flex;
  position: relative
}

.steps ul li {
  width: 20.16%;
  margin-right: 10px;
  border-bottom: none;
}

.steps ul li a {
  display: inline-block;
  width: 100%;
  height: 7px;
  background: #e6e6e6;
  border-radius: 3.5px
}

.steps ul li.first a,
.steps ul li.checked a {
  background: #011142;
  transition: all .5s ease
}



.steps ul.step-2:before {
  content: "Additional Information"
}

.steps ul.step-3:before {
  content: "Your Order"
}

.steps ul.step-4:before {
  content: "Billing Method"
}

h3 {
  font-family: poppins-semibold
}

.form-row {
  margin-bottom: 14px
}

.form-row label {
  margin-bottom: 8px;
  display: block;
  color: black;
}
.form-group{
  margin-bottom:14px !important;
}

.form-row.form-group {
  display: flex
}

.form-row.form-group .form-holder {
  width: 47%;
  margin-right: 21px
}

.form-row.form-group .form-holder:last-child {
  margin-right: 0
}

.form-holder {
  position: relative
}

.form-holder i {
  position: absolute;
  top: 11px;
  right: 19px;
  font-size: 17px;
  color: #999
}

.form-control2 {
  height: 42px;
  border: 1px solid #d2d3d7;
  background: 0 0;
  width: 100%;
  padding: 0 18px;
  color: #818184;
}

.form-control2:focus {
  border-color: #011142
}

.form-control2::-webkit-input-placeholder {
  color: #999;
  font-size: 13px
}

.form-control2::-moz-placeholder {
  color: #999;
  font-size: 13px
}

.form-control2:-ms-input-placeholder {
  color: #999;
  font-size: 13px
}

.form-control2:-moz-placeholder {
  color: #999;
  font-size: 13px
}

textarea.form-control2 {
  padding-top: 11px;
  padding-bottom: 11px
}

.option {
  color: #999
}

.actions ul {
  display: flex;
  margin-top: 30px;
  justify-content: space-between
}

.actions ul.step-last {
  justify-content: flex-end
}

.actions ul.step-last li:first-child {
  display: none
}

.actions li a {
  padding: 0;
  border: none;
  display: inline-flex;
  height: 51px;
  width: 135px;
  align-items: center;
  background: #011142;
  cursor: pointer;
  position: relative;
  padding-left: 41px;
  color: #FFF;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
  font-weight: 400
}
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid #000 !important;
}
.actions li a:before {
  content: '\f2ee';
  position: absolute;
  top: 19px;
  right: 41px;
  font-family: Material-Design-Iconic-Font;
  -webkit-transform: translateZ(0);
  transform: translateZ(0)
}

.actions li a:hover {
  background: #011142
}

.actions li a:hover:before {
  -webkit-animation-name: hvr-icon-wobble-horizontal;
  animation-name: hvr-icon-wobble-horizontal;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1
}

.actions li[aria-disabled=true] a {
  display: none
}

.actions li:first-child a {
  background: #e6e6e6;
  padding-left: 48px
}

.actions li:first-child a:before {
  content: '\f2ea';
  left: 26px
}

.actions li:first-child a:hover {
  background: #ccc
}

.actions li:last-child a {
  padding-left: 29px;
  width: 167px;
  font-weight: 400
}

.actions li:last-child a:before {
  right: 30px
}

.checkbox {
  position: relative
}

.checkbox label {
  padding-left: 21px;
  cursor: pointer;
  color: #999
}

.checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer
}

.checkbox input:checked~.checkmark:after {
  display: block
}

.checkmark {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  height: 12px;
  width: 13px;
  border-radius: 2px;
  background-color: #ebebeb;
  border: 1px solid #ccc;
  font-family: Material-Design-Iconic-Font;
  color: #000;
  font-size: 10px;
  font-weight: bolder
}

.checkmark:after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
  content: '\f26b'
}

.checkbox-circle {
  margin-top: 41px;
  margin-bottom: 46px
}

.checkbox-circle label {
  cursor: pointer;
  padding-left: 26px;
  color: #999;
  display: block;
  margin-bottom: 15px;
  position: relative
}

.checkbox-circle label.active .tooltip {
  display: block
}

.checkbox-circle input {
  position: absolute;
  opacity: 0;
  cursor: pointer
}

.checkbox-circle input:checked~.checkmark:after {
  display: block
}

.checkbox-circle .checkmark {
  position: absolute;
  top: 11px;
  left: 0;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  background: #ebebeb;
  border: 1px solid #cdcdcd
}

.checkbox-circle .checkmark:after {
  content: "";
  top: 6px;
  left: 6px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #666;
  position: absolute;
  display: none
}

.checkbox-circle .tooltip {
  padding: 9px 22px;
  background: #f2f2f2;
  line-height: 1.8;
  position: relative;
  margin-top: 16px;
  margin-bottom: 28px;
  display: none
}

.checkbox-circle .tooltip:before {
  content: "";
  border-bottom: 10px solid #f2f2f2;
  border-right: 9px solid transparent;
  border-left: 9px solid transparent;
  position: absolute;
  bottom: 100%
}

.product {
  margin-bottom: 33px
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 30px;
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 30px
}

.item:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border: none
}

.item .left {
  display: flex;
  align-items: center
}

.item .thumb {
  display: inline-flex;
  width: 100px;
  height: 90px;
  justify-content: center;
  align-items: center;
  border: 1px solid #f2f2f2
}

.item .purchase {
  display: inline-block;
  margin-left: 21px
}

.item .purchase h6 {
  font-family: poppins-medium;
  font-size: 16px;
  margin-bottom: 4px;
  font-weight: 500
}

.item .purchase h6 a {
  color: #333
}

.item .price {
  font-size: 16px
}

.checkout {
  margin-bottom: 44px
}

.checkout span.heading {
  font-family: poppins-medium;
  font-weight: 500;
  margin-right: 5px
}

.checkout .subtotal {
  margin-bottom: 18px
}

.checkout .shipping {
  margin-bottom: 19px
}

.checkout .shipping span.heading {
  margin-right: 4px
}

.checkout .total-price {
  font-family: muli-bold;
  color: #333;
  font-weight: 700
}

.logo {
  position: fixed;
  top: 40%;
  margin-left: 40px;
}

@-webkit-keyframes hvr-icon-wobble-horizontal {
  16.65% {
    -webkit-transform: translateX(6px);
    transform: translateX(6px)
  }

  33.3% {
    -webkit-transform: translateX(-5px);
    transform: translateX(-5px)
  }

  49.95% {
    -webkit-transform: translateX(4px);
    transform: translateX(4px)
  }

  66.6% {
    -webkit-transform: translateX(-2px);
    transform: translateX(-2px)
  }

  83.25% {
    -webkit-transform: translateX(1px);
    transform: translateX(1px)
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0)
  }
}

@keyframes hvr-icon-wobble-horizontal {
  16.65% {
    -webkit-transform: translateX(6px);
    transform: translateX(6px)
  }

  33.3% {
    -webkit-transform: translateX(-5px);
    transform: translateX(-5px)
  }

  49.95% {
    -webkit-transform: translateX(4px);
    transform: translateX(4px)
  }

  66.6% {
    -webkit-transform: translateX(-2px);
    transform: translateX(-2px)
  }

  83.25% {
    -webkit-transform: translateX(1px);
    transform: translateX(1px)
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0)
  }
}

.titleh{
    font-size: 22px;
    font-family: poppins-semibold;
    color: #333;
    top: 70px;
    position: absolute;
}
.profileprogress{
  font-size: 12px;
    font-family: poppins-semibold;
    color: #333;
    top: -70px;
    position: relative;
    left: 68%;
    width: 30%;
}

@media(max-width:1500px) {
  .wrapperat {
    height: auto
  }
}

@media(max-width:1199px) {
  .wrapperat {
    height: 100vh
  }

  #wizard {
    margin-right: 40px;
    min-height: 829px;
    padding-left: 60px;
    padding-right: 60px
  }
}

@media(max-width:991px) {
  .wrapperat {
    justify-content: center
  }

  .wrapperat .image-holder {
    display: none
  }

  .logo {
    position: relative;
    padding: 15px;
  }

  .wrapperat form {
    width: 60%
  }

  #wizard {
    margin-right: 0;
    padding-left: 40px;
    padding-right: 40px
  }
  .titleh{
    font-size: 22px;
    font-family: poppins-semibold;
    color: #333;
    top: 0px;
    position: relative;
    
}
}

@media(max-width:767px) {
  section{
    margin-top: 50px;
  }
  .titleh{
    font-size: 22px;
    font-family: poppins-semibold;
    color: #333;
    top: 0px;
    position: relative;
}
  .wrapperat {
    height: auto;
    display: block
  }

  .wrapperat .image-holder {
    width: 100%;
    display: block
  }

  .logo {
    position: relative;
    padding: 15px;
    text-align:center !important;
    margin:auto;
  }

  .wrapperat form {
    width: 100%
  }

  #wizard {
    min-height: unset;
    padding: 70px 20px 40px
  }

  .form-row.form-group {
    display: block
  }

  .form-row.form-group .form-holder {
    width: 100%;
    margin-right: 0;
  }

  .item .purchase {
    margin-left: 11px
  }
  .logoimg{
  width: 350px;
    border: solid 1px #fff;
}
}


textarea {
  height: 70px !important;
}
.errtext{
  color: #e00b0b;
}
.error-border{
  border: 1px solid #e00b0b;
}
.uppertext{
  text-transform: uppercase;
}
.qalificationbox{
  background: rgb(1, 17, 66);
    border-radius: 5px;
    margin: 5px;
    color: #fff;
    padding: 10px;
}
.qclose{
  color:#fff;
}
canvas{
  border: solid 1px #000;
}
.clearbtn{
    border-radius: 0px 0px 5px 5px;
    border: solid 1px #890404;
    background: #890404;
    color: #fff;
    font-size: 14px;
}
.Uploadsingbtn{
  border-radius: 0px 0px 5px 5px;
    border: solid 1px #170262;
    background: #170262;
    color: #fff;
    font-size: 14px;
    margin-left: 5px;
    margin-right: 5px;
}
.Uploaddocbtn{
  border-radius: 5px;
    border: solid 1px #170262;
    background: #170262;
    color: #fff;
    font-size: 14px;
    margin-left: 5px;
    padding: 10px;
}
.mytable{
  border: solid 1px #666
}
.mytable th {
  background-color: #011142;
  color: #fff;
  font-size: 12px;
}
.mytable .form-control2{
  font-size: 12px;
  padding: 5px;
}
.logoimg{
  width: 350px;
    border: solid 1px #fff;
    margin-top: 100px;margin: 10px; padding: 5px;
}
.captcha-container {
  display: flex;
  align-items: center;
}
.captcha-canvas {
  border: 1px solid #000;
  margin-right: 10px;
}
#logoutpanel {
  position: absolute;
    top: 10px;
}
</style>
